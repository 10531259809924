<template>
    <div class="has-text-left">
        <p class='title'>{{Flag.alias}}</p>
        <p>
            <span class="has-text-weight-bold">{{ $t('management.dashboard.card.condition') }}</span>:
            <span>{{ LastOperationReportStatus }}</span><br>
            <span class="is-italic is-size-7">{{ LastOperationReportNotes }}</span>
        </p>
        <hr>
        <p>
            <span class="has-text-weight-bold">{{ $t('management.dashboard.card.flightNoProblem') }}</span>: <br>
            <span>{{ FormattedOperationHours['flying-no-problem'].days }} {{ $t('management.dashboard.card.days') }}, </span>
            <span>{{ FormattedOperationHours['flying-no-problem'].hours }} {{ $t('management.dashboard.card.hours') }}, </span>
            <span>{{ FormattedOperationHours['flying-no-problem'].minutes }} {{ $t('management.dashboard.card.minutes') }}, {{ $t('management.dashboard.card.and') }} </span>
            <span>{{ FormattedOperationHours['flying-no-problem'].seconds }} {{ $t('management.dashboard.card.seconds') }} </span><br>
            <span class="is-size-7">({{ FormattedOperationHours['flying-no-problem']['hours-total'] }} {{ $t('management.dashboard.card.totalHours') }}) </span>
        </p>
        <hr>
        <p>
            <span class="has-text-weight-bold">{{ $t('management.dashboard.card.anchored') }}</span>: <br>
            <span>{{ FormattedOperationHours['anchored'].days }} {{ $t('management.dashboard.card.days') }}, </span>
            <span>{{ FormattedOperationHours['anchored'].hours }} {{ $t('management.dashboard.card.hours') }}, </span>
            <span>{{ FormattedOperationHours['anchored'].minutes }} {{ $t('management.dashboard.card.minutes') }}, {{ $t('management.dashboard.card.and') }} </span>
            <span>{{ FormattedOperationHours['anchored'].seconds }} {{ $t('management.dashboard.card.seconds') }} </span><br>
            <span class="is-size-7">({{ FormattedOperationHours['anchored']['hours-total'] }} {{ $t('management.dashboard.card.totalHours') }}) </span>
        </p>
    </div>
</template>
<style lang="scss" scope>
</style>
<script>
export default {
    name: "DashboardCard",
    props: {
        Flag: Object
    },
    data() {
        return {
            OperationHours: [],
            FormattedOperationHours: {
                'flying-no-problem': { days: null, hours: null, minutes: null, seconds: null },
                'anchored': { days: null, hours: null, minutes: null, seconds: null }
            },
            LastOperationReportStatus: '',
            LastOperationReportNotes: ''
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser'];
        }
    },
    methods: {
        GetFormattedOperationHours() {
            let operationHours = {
                'flying-no-problem': { days: null, hours: null, minutes: null, seconds: null, 'hours-total': null },
                'anchored': { days: null, hours: null, minutes: null, seconds: null, 'hours-total': null }
            }
            let tempOperationHours = this.OperationHours

            operationHours['flying-no-problem']['hours-total'] = Math.floor(tempOperationHours['flying-no-problem'] / 3600)
            operationHours['flying-no-problem'].days = Math.floor(tempOperationHours['flying-no-problem'] / 86400)
            tempOperationHours['flying-no-problem'] -= operationHours['flying-no-problem'].days * 86400
            operationHours['flying-no-problem'].hours = Math.floor(tempOperationHours['flying-no-problem'] / 3600) % 24
            tempOperationHours['flying-no-problem'] -= operationHours['flying-no-problem'].hours * 3600
            operationHours['flying-no-problem'].minutes = Math.floor(tempOperationHours['flying-no-problem'] / 60) % 24
            tempOperationHours['flying-no-problem'] -= operationHours['flying-no-problem'].minutes * 60
            operationHours['flying-no-problem'].minutes = Math.floor(tempOperationHours['flying-no-problem'] / 60) % 24
            operationHours['flying-no-problem'].seconds = Math.floor(tempOperationHours['flying-no-problem'] % 60)

            operationHours['anchored']['hours-total'] = Math.floor(tempOperationHours['anchored'] / 3600)
            operationHours['anchored'].days = Math.floor(tempOperationHours['anchored'] / 86400)
            tempOperationHours['anchored'] -= operationHours['anchored'].days * 86400
            operationHours['anchored'].hours = Math.floor(tempOperationHours['anchored'] / 3600) % 24
            tempOperationHours['anchored'] -= operationHours['anchored'].hours * 3600
            operationHours['anchored'].minutes = Math.floor(tempOperationHours['anchored'] / 60) % 24
            tempOperationHours['anchored'] -= operationHours['anchored'].minutes * 60
            operationHours['anchored'].minutes = Math.floor(tempOperationHours['anchored'] / 60) % 24
            operationHours['anchored'].seconds = Math.floor(tempOperationHours['anchored'] % 60)

            this.FormattedOperationHours = operationHours
        },
        GetFormattedOperationStatus(status) {
            switch (status) {
                case 'flying-no-problem':
                    this.LastOperationReportStatus = 'Em voo sem problemas 🟢'
                    break
                case 'flying-with-problem':
                    this.LastOperationReportStatus = 'Em voo com problemas 🔴'
                    break
                case 'anchored':
                    this.LastOperationReportStatus = 'Ancorado 🔴'
                    break
                case 'takeoff':
                    this.LastOperationReportStatus = 'Início de voo 🟢'
                    break
                case 'partial':
                    this.LastOperationReportStatus = 'Mudança de altitude 🟢'
                    break
                case 'landing':
                    this.LastOperationReportStatus = 'Recolhimento completo 🔴'
                    break
            }
        }
    },
    created() {
        this.$store.dispatch('database/GetFromDatabase', {
            path: 'operation-notes-hours/' + this.Flag.name.toLowerCase(),
            returnSnapshotVal: false
        }).then(snapshot => {
            this.OperationHours = snapshot.val()
            this.$store.dispatch('database/GetLimitedFromDatabase', {
                path: 'operation-notes/' + this.Flag.name.toLowerCase() + '/' + new Date().toLocaleDateString('pt-br').split('/').join('-'),
                limit: 1,
                returnSnapshotVal: false
            }).then(snapshot => {
                this.GetFormattedOperationStatus(snapshot.val()[Object.keys(snapshot.val())].operationReportObject.aerostatInfo.status)
                this.LastOperationReportNotes = snapshot.val()[Object.keys(snapshot.val())].operationReportObject.notes
                this.GetFormattedOperationHours()
            })
        })
    },
}
</script>