import { render, staticRenderFns } from "./AboutVersion.vue?vue&type=template&id=4383c37d&scoped=true&"
import script from "./AboutVersion.vue?vue&type=script&lang=js&"
export * from "./AboutVersion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4383c37d",
  null
  
)

export default component.exports