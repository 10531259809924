<template>
    <b-autocomplete v-model="SelectedEquipment" :data="GetFilteredEquipments" field="name" @select="option => $emit('input', option)" :open-on-focus="true">
        <template slot="header">
            <a @click="ShowAddEquipmentDialog">
                <span>Adicionar novo...</span>
            </a>
        </template>
        <template slot="empty">Sem resultados paras {{ SelectedEquipment }}</template>
    </b-autocomplete>
</template>
<script>
export default {
    name: 'MaintenanceAutocompleteInput',
    data() {
        return {
            SelectedEquipment: '', //Current SelectedEquipment hirer from autocomplete list.
        }
    },
    computed: {
        GetFilteredEquipments() { //Filter user input to search on autocomplete list.
            return this.$store.getters['database/GetEquipments'].filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(this.SelectedEquipment.toLowerCase()) >= 0
            })
        }
    },
    methods: {
        ShowAddEquipmentDialog() { //Function to show dialog to add a new hirer to autocomplete list.
            this.$buefy.dialog.prompt({ //Displays dialog.
                message: `Novo equipamento`, //Message on dialog box.
                inputAttrs: {
                    maxlength: 20, //Maximum of chars on dialog's input.
                    value: this.SelectedEquipment //Placeholder value on dialog's input is what the user has already typed.
                },
                confirmText: 'Adicionar', //Text on dialog's confirm button.
                cancelText: 'Cancelar', //Text on dialog's cancel button.
                onConfirm: (value) => { //Fired when the user clicks the confirm button.
                    this.SelectedEquipment = value //This compoenent's SelectedEquipment variable become what the user has typed on this dialog's input.
                    this.$emit('input', value) //The new value is emmited via input event to parent component so it can capture the new value via v-model.
                    this.$store.dispatch('database/PushToDatabase', { path: 'maintenance/equipments', object: { name: value.toLowerCase() } })
                }
            })
        }
    },
    created() {
        this.$store.dispatch('database/bindEquipments')
    }
}
</script>