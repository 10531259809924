<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(ConfirmSignUser)">
            <b-field>
                <ValidationProvider name="email" rules="required" v-slot="{ errors, valid }">
                    <b-field label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Nome</span>
                        </template>
                        <b-input v-model="NewUserObject.Name" icon="user" expanded></b-input>
                    </b-field>
                </ValidationProvider>
            </b-field>
            <b-field>
                <ValidationProvider name="email" rules="required|email" v-slot="{ errors, valid }">
                    <b-field label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Email</span>
                        </template>
                        <b-input v-model="NewUserObject.Email" icon="at" expanded></b-input>
                    </b-field>
                </ValidationProvider>
            </b-field>
            <b-field>
                <ValidationProvider name="email" rules="required" v-slot="{ errors, valid }">
                    <b-field label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Senha</span>
                        </template>
                        <b-input v-model="NewUserObject.Password" icon="key" expanded></b-input>
                    </b-field>
                </ValidationProvider>
            </b-field>
            <b-field>
                <b-field label-position="on-border">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">Empresa</span>
                    </template>
                    <b-input v-model="NewUserObject.Company" icon="key" expanded></b-input>
                </b-field>
            </b-field>
            <b-field>
                <b-field label-position="on-border">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">Operação</span>
                    </template>
                    <HirerAutocompleteTagInput v-model="NewUserObject.Flag" />
                </b-field>
            </b-field>
            <b-field>
                <ValidationProvider name="email" rules="required" v-slot="{ errors, valid }">
                    <b-field label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Papel</span>
                        </template>
                        <b-select v-model="NewUserObject.Role" icon="user-tag" expanded>
                            <option value="admin">Administrador</option>
                            <option value="supervisor">Supervisor</option>
                            <option value="operator">Operador</option>
                            <option value="client">Cliente</option>
                        </b-select>
                    </b-field>
                </ValidationProvider>
            </b-field>
            <b-field label-position="on-border" class="is-vertical-spaced">
                <template slot="label">
                    <span class="has-text-primary is-family-sans-serif">Permissões</span>
                </template>
            </b-field>
            <b-field class="has-text-left">
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.livestreamview"><span class="has-text-weight-semibold">Transmissão ao vivo</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.georefview"><span class="has-text-weight-semibold">Georreferenciamento</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.cameracontrol"><span class="has-text-weight-semibold">Controle da câmera</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.alertsview"><span class="has-text-weight-semibold">Visualização de alertas do SAURON</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.alertsremoving"><span class="has-text-weight-semibold">Exclusão de alertas do SAURON</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.assistedsauron"><span class="has-text-weight-semibold">SAURON assistido</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.sauronassistant"><span class="has-text-weight-semibold">SAURON Auxiliar</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.mordorondemand"><span class="has-text-weight-semibold">MORDOR on demand</span></b-switch>
                <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.newmordor"><span class="has-text-weight-semibold">New MORDOR</span></b-switch>
            </b-field>
            <div class="py-4" v-if="NewUserObject.Permissions.mordorondemand">
                <b-field label-position="on-border" class="is-vertical-spaced">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">Permissões - MORDOR on demand</span>
                    </template>
                </b-field>
                <b-field class="has-text-left">
                    <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.mordorReqStream"><span class="has-text-weight-semibold">MORDOR on demand - Streaming</span></b-switch>
                    <b-switch :rounded="false" type="is-primary" v-model="NewUserObject.Permissions.mordorReqFrame"><span class="has-text-weight-semibold">MORDOR on demand - Frames</span></b-switch>
                </b-field>
            </div>
            <b-button native-type="submit" expanded type="is-primary" :loading="IsConfirmButtonSpinning" outlined><span class="is-family-sans-serif">Confirmar</span></b-button>
        </form>
    </ValidationObserver>
</template>
<style lang="scss" scoped>
.is-vertical-spaced {
    padding-bottom: 0.1vh;
}
</style>
<script>
import HirerAutocompleteTagInput from '@/components/management/HirerAutocompleteTagInput.vue'
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
extend('email', email)
extend('required', required)
export default {
    name: 'UserInsert',
    components: {
        HirerAutocompleteTagInput,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            NewUserObject: {
                Name: '',
                Email: '',
                Password: '',
                Company: '',
                Flag: '',
                Role: '',
                Permissions: {
                    livestreamview: false,
                    georefview: false,
                    cameracontrol: false,
                    alertsview: false,
                    alertsremoving: false,
                    assistedsauron: false,
                    mordorondemand: false,
                    mordorReqStream: false,
                    mordorReqFrame: false,
                },
            },
            IsConfirmButtonSpinning: false
        }
    },
    methods: {
        ConfirmSignUser() {
            this.IsConfirmButtonSpinning = true
            this.$store.dispatch('user/Signup', this.NewUserObject)
                .then(() => {
                    this.IsConfirmButtonSpinning = false
                    this.LaunchToast('Usuário inserido com sucesso!', 'is-success')
                    this.$store.dispatch('user/SendPasswordReset', this.NewUserObject.Email)
                })
                .catch(error => {
                    this.IsConfirmButtonSpinning = false
                    this.LaunchToast('Erro ao inserir usuário: ' + error, 'is-danger')
                })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000
            })
        }
    }
}
</script>