<template>
    <b-table :data="OperationReports" :paginated="IsPaginated" :striped="IsStriped" :narrowed="IsNarrowed" :bordered="IsBordered" :per-page="PerPage" :current-page.sync="CurrentPage" :pagination-simple="IsPaginationSimple" :pagination-position="PaginationPosition" :default-sort-direction="DefaultSortDirection" :sort-icon="SortIcon" :sort-icon-size="SortIconSize" default-sort="operationReportObject.timestamp" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">
        <b-table-column field="operationReportObject.type" label="Tipo" v-slot="props" centered sortable numeric> {{ props.row.operationReportObject.type}}</b-table-column>
        <b-table-column field="operationReportObject.timestamp" label="Data" v-slot="props" centered sortable numeric> {{ new Date(props.row.operationReportObject.timestamp).toLocaleDateString('pt-br')}}
        </b-table-column>
        <b-table-column field="operationReportObject.timestamp" label="Hora" v-slot="props" centered sortable numeric> {{ new Date(props.row.operationReportObject.timestamp).toLocaleTimeString('pt-br')}}
        </b-table-column>
        <b-table-column field="operationReportObject.notes" label="Observações" v-slot="props" centered sortable numeric> {{ props.row.operationReportObject.notes}}</b-table-column>
        <b-table-column field="operationReportObject.aerostatInfo.altitude" label="Altitude (metros)" v-slot="props" centered sortable numeric>
            <template v-if="props.row.operationReportObject.aerostatInfo.altitude">{{ props.row.operationReportObject.aerostatInfo.altitude}}</template>
            <template v-else>0</template>
        </b-table-column>
        <b-table-column field="operationReportObject.weatherDataObject" label="Dados meteorológicos" v-slot="props" centered sortable numeric>
            <template v-if="props.row.operationReportObject.weatherDataObject">
                <p class="is-size-7">
                    <span class="has-text-weight-bold">Cemaden</span>:
                    Nuvens = {{props.row.operationReportObject.weatherDataObject.cemaden.clouds}}
                </p>
                <p class="is-size-7">
                    <span class="has-text-weight-bold">Estação local</span>:
                    Pressão = {{props.row.operationReportObject.weatherDataObject.localStation.atmosphericPressure}},
                    Ventos = {{props.row.operationReportObject.weatherDataObject.localStation.windSpeed}}
                </p>
                <p class="is-size-7">
                    <span class="has-text-weight-bold">Anemômetro</span>:
                    Ventos = {{props.row.operationReportObject.weatherDataObject.local.windSpeed}}
                </p>
                <p class="is-size-7">
                    <span class="has-text-weight-bold">Tupan</span>:
                    Status = {{props.row.operationReportObject.weatherDataObject.tupan}},
                </p>
                <p class="is-size-7">
                    <span class="has-text-weight-bold">Windy</span>:
                    Rajadas = {{props.row.operationReportObject.weatherDataObject.windy.gustSpeed}},
                    Ventos = {{props.row.operationReportObject.weatherDataObject.windy.windSpeed}}
                </p>
                <p class="is-size-7" v-if="props.row.operationReportObject.weatherDataObject.lightningDetector">
                    <span class="has-text-weight-bold">Detector de raios</span>:
                    Distância = {{props.row.operationReportObject.weatherDataObject.lightningDetector.distance}}
                    Campo elétrico = {{props.row.operationReportObject.weatherDataObject.lightningDetector.lightning}}
                </p>
            </template>
            <template v-else>Não informado</template>
        </b-table-column>
        <b-table-column field="operationReportObject.aerostatInfo.status" label="Status" v-slot="props" centered sortable numeric>
            <template v-if="props.row.operationReportObject.aerostatInfo.status=='anchored'">Ancorado</template>
            <template v-else-if="props.row.operationReportObject.aerostatInfo.status=='takeoff'">Início de voo</template>
            <template v-else-if="props.row.operationReportObject.aerostatInfo.status=='flying-no-problem'">Voando sem problemas</template>
            <template v-else-if="props.row.operationReportObject.aerostatInfo.status=='flying-with-problem'">Voando com problemas</template>
            <template v-else-if="props.row.operationReportObject.aerostatInfo.status=='landing'">Recolhimento total</template>
            <template v-else-if="props.row.operationReportObject.aerostatInfo.status=='partial'">Recolhimento parcial</template>
        </b-table-column>
        <b-table-column field="operationReportObject.aerostatInfo.stoppedFlightCondition" label="Causa da parada" v-slot="props" centered sortable numeric>
            <template v-if="props.row.operationReportObject.aerostatInfo.stoppedFlightCondition=='meteorology'">Meteorológico</template>
            <template v-else-if="props.row.operationReportObject.aerostatInfo.stoppedFlightCondition=='maintenance'">Manutenção</template>
            <template v-else-if="props.row.operationReportObject.aerostatInfo.stoppedFlightCondition==''">Sem parada</template>
        </b-table-column>
        <b-table-column field="operationReportObject.author" label="Autor" v-slot="props" centered sortable numeric> {{ props.row.operationReportObject.author}}</b-table-column>
    </b-table>
</template>
<script>
export default {
    name: 'ReportTableOperationReport',
    props: {
        OperationReports: Array
    },
    data() {
        return {
            IsPaginated: true,
            IsPaginationSimple: false,
            IsStriped: false,
            IsBordered: false,
            IsNarrowed: false,
            PaginationPosition: 'bottom',
            DefaultSortDirection: 'desc',
            SortIcon: 'arrow-up',
            SortIconSize: 'is-small',
            CurrentPage: 1,
            PerPage: 6
        }
    }
}
</script>