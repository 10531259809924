<template>
<div class="columns ">
    
    <div class="column bordered">
        <span class="is-size-2">Disponibilidade Cordão Nova Vista</span>
        <div class="is-size-4 left mb-4"> Última atualização
            <div>Data: {{new Date(this.lastCordao.timestamp).toLocaleDateString()}}</div>
            <div>Hora: {{new Date(this.lastCordao.timestamp).toLocaleTimeString()}}</div>
            <div>Usuário: {{this.lastCordao.user}}</div>
            <div>Disponibilidade: {{this.lastCordao.availability}}</div>
        </div>
        <b-button type="is-info"  @click="isImageModalCordao = true">Inserir Disponibilidade</b-button>
        <b-modal v-model="isImageModalCordao"> 
             <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Cordão Nova Vista</p>                        
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Disponibilidade">
                            <b-input
                                type="text" size="is-large"  v-model="availabilityCordao"                              
                                placeholder="Nova disponibilidade"
                                required>
                            </b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">                      
                        <b-button expanded size="is-large" type="is-success"  @click="insertAvailability('cordao')"> Salvar</b-button>
                    </footer>
                </div>
        </b-modal>
    </div>
    
    <div class="column bordered">
        <span class="is-size-2">Disponibilidade Doutor</span>
        <div class="is-size-4 left mb-4"> Última atualização
            <div>Data: {{new Date(this.lastDoutor.timestamp).toLocaleDateString()}}</div>
            <div>Hora: {{new Date(this.lastDoutor.timestamp).toLocaleTimeString()}}</div>
            <div>Usuário: {{this.lastDoutor.user}}</div>
            <div>Disponibilidade: {{this.lastDoutor.availability}}</div>
        </div>
        <b-button type="is-info"  @click="isImageModalDoutor = true">Inserir Disponibilidade</b-button>
        <b-modal v-model="isImageModalDoutor"> 
             <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Doutor</p>                        
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Disponibilidade">
                            <b-input
                                type="text" size="is-large"  v-model="availabilityDoutor"                              
                                placeholder="Nova disponibilidade"
                                required>
                            </b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">                      
                        <b-button expanded size="is-large" type="is-success" actived @click="insertAvailability('doutor')"> Salvar</b-button>
                    </footer>
                </div>
        </b-modal>
    </div>
</div>
</template>
<style lang="scss" scoped>

.left{
    text-align: left;
}
.bordered{
    border-style: solid;
}
</style>
<script>
export default {
    name: 'MordorAvailability',
    data() {
        return {
            isImageModalCordao:false,
            isImageModalDoutor:false,
            availabilityDoutor:'',
            availabilityCordao:'',
            lastDoutor:{},
            lastCordao:{},
        }
    },
    computed:{
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        }
    },    
    methods: {
        insertAvailability(hirer){
            let availabilityObject={
                user:this.GetCurrentUser.email,
                timestamp:new Date().getTime(),                
            }
            switch (hirer) {
                case 'cordao':
                    availabilityObject.availability = this.availabilityCordao                    
                    this.$store.dispatch('database/SetOnDatabase', {path:'mordor-sites/mordor-vale-1/availability/current',object:availabilityObject}).then(()=>{
                        this.$store.dispatch('database/SetOnDatabase', {path:'mordor-sites/mordor-vale-1/availability/logs/'+new Date(availabilityObject.timestamp).toLocaleDateString().replaceAll('/','-')+'/'+new Date(availabilityObject.timestamp).toLocaleTimeString(),object:availabilityObject})
                        .then(()=>{
                            this.$store.dispatch('database/SetOnDatabase', {path:'mordor-sites/mordor-vale-1/availability/date-index/'+new Date(availabilityObject.timestamp).toLocaleDateString().replaceAll('/','-'),object:true})
                        })
                        .then(()=>{
                            this.LaunchToast('Disponibilidade atualizada!', 'is-success')
                        })
                        .catch((error)=>{
                            this.LaunchToast('Erro:'+error.message, 'is-danger')

                        })
                    })
                    break;
               case 'doutor':
                    availabilityObject.availability = this.availabilityDoutor                   
                    this.$store.dispatch('database/SetOnDatabase', {path:'mordor-sites/mordor-vale-2/availability/current',object:availabilityObject}).then(()=>{
                        this.$store.dispatch('database/SetOnDatabase', {path:'mordor-sites/mordor-vale-2/availability/logs/'+new Date(availabilityObject.timestamp).toLocaleDateString().replaceAll('/','-')+'/'+new Date(availabilityObject.timestamp).toLocaleTimeString(),object:availabilityObject})
                        .then(()=>{
                            this.$store.dispatch('database/SetOnDatabase', {path:'mordor-sites/mordor-vale-2/availability/date-index/'+new Date(availabilityObject.timestamp).toLocaleDateString().replaceAll('/','-'),object:true})
                        })
                        .then(()=>{
                            this.LaunchToast('Disponibilidade atualizada!', 'is-success')
                        })
                        .catch((error)=>{
                            this.LaunchToast('Erro:'+error.message, 'is-danger')

                        })
                    })
                    break;
            
                default:
                    break;
            }
            this.isImageModalCordao=false,
            this.isImageModalDoutor=false,
            this.lastAvailability()

        },
        lastAvailability(){
            this.$store.dispatch('database/GetFromDatabase', {
                path: 'mordor-sites/mordor-vale-1/availability/current',
                returnSnapshotVal: true
            }).then((res)=>{
                this.lastCordao = res                
            })
            this.$store.dispatch('database/GetFromDatabase', {
                path: 'mordor-sites/mordor-vale-2/availability/current',
                returnSnapshotVal: true
            }).then((res)=>{
                this.lastDoutor = res                
            })
            
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000
            })
        }
    
    },
    created() {
        this.lastAvailability()
    }    
}
</script>