<template>
    <b-table :data="acessLogObject" :paginated="IsPaginated" :striped="IsStriped" :narrowed="IsNarrowed" :bordered="IsBordered" :per-page="PerPage" :current-page.sync="CurrentPage" :pagination-simple="IsPaginationSimple" :pagination-position="PaginationPosition" :default-sort-direction="DefaultSortDirection" :sort-icon="SortIcon" :sort-icon-size="SortIconSize" default-sort="LogObject.hour" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">
        <b-table-column field="LogObject.user" label="Usuário" width="40" v-slot="props" sortable searchable>
            {{ props.row.LogObject.user }}
        </b-table-column>
        <b-table-column field="LogObject.region" label="Região" width="40" v-slot="props" sortable>
            {{ props.row.LogObject.region }}
        </b-table-column>
        <b-table-column field="LogObject.city" label="Cidade" width="40" v-slot="props" sortable>
            {{ props.row.LogObject.city }}
        </b-table-column>
        <b-table-column field="LogObject.ip" label="Endereço IP" width="40" v-slot="props" sortable>
            {{ props.row.LogObject.ip }}
        </b-table-column>
        <b-table-column field="LogObject.action" label="Ação" width="40" v-slot="props" sortable>
            {{ props.row.LogObject.action }}
        </b-table-column>
        <b-table-column field="LogObject.hour" label="Data" width="20" v-slot="props" sortable>
            {{ new Date(props.row.LogObject.hour).toLocaleDateString('pt-br') }}
        </b-table-column>
        <b-table-column field="LogObject.hour" label="Hora" width="20" v-slot="props" sortable>
            {{ new Date(props.row.LogObject.hour).toLocaleTimeString('pt-br') }}
        </b-table-column>
    </b-table>
</template>
<style lang="scss">
</style>
<script>
export default {
    name: 'ReportTableUserPresence',
    props: {
        acessLogObject: Array
    },
    data() {
        return {
            IsPaginated: true,
            IsPaginationSimple: false,
            IsStriped: false,
            IsBordered: false,
            IsNarrowed: false,
            PaginationPosition: 'bottom',
            DefaultSortDirection: 'desc',
            SortIcon: 'arrow-up',
            SortIconSize: 'is-small',
            CurrentPage: 1,
            PerPage: 6
        }
    }
}
</script>