<template>
    <b-autocomplete icon="users" v-model="SelectedHirer" :data="GetFilteredHirers" field="name" @input="option => $emit('input', option)" @select="option => $emit('input', option)" :open-on-focus="true">
        <template slot="header">
            <a @click="ShowAddHirerDialog" v-if="CanInsertHirer">
                <span>Adicionar novo...</span>
            </a>
        </template>
        <template slot="empty">Sem resultados paras {{ SelectedHirer }}</template>
    </b-autocomplete>
</template>
<script>
export default {
    name: 'HirerAutocompleteInput',
    props: {
        CanInsertHirer: Boolean
    },
    data() {
        return {
            SelectedHirer: '', //Current SelectedHirer hirer from autocomplete list.
        }
    },
    computed: {
        GetFilteredHirers() { //Filter user input to search on autocomplete list.
            return this.$store.getters['database/GetHirers'].filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(this.SelectedHirer.toLowerCase()) >= 0
            })
        }
    },
    methods: {
        ShowAddHirerDialog() { //Function to show dialog to add a new hirer to autocomplete list.
            this.$buefy.dialog.prompt({ //Displays dialog.
                message: `Novo(a) contratante/operação`, //Message on dialog box.
                inputAttrs: {
                    maxlength: 20, //Maximum of chars on dialog's input.
                    value: this.SelectedHirer //Placeholder value on dialog's input is what the user has already typed.
                },
                confirmText: 'Confirmar', //Text on dialog's confirm button.
                cancelText: 'Cancelar', //Text on dialog's cancel button.
                onConfirm: (value) => { //Fired when the user clicks the confirm button.
                    this.SelectedHirer = value //This compoenent's SelectedHirer variable become what the user has typed on this dialog's input.
                    this.$emit('input', value) //The new value is emmited via input event to parent component so it can capture the new value via v-model.
                    // this.$store.dispatch('database/PushToDatabase', { path: 'hirers', object: { name: value, alias: value } })
                }
            })
        }
    },
    created() {
        this.$store.dispatch('database/bindHirers')
    }
}
</script>