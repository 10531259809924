<template>
    <ValidationObserver v-slot="{ handleSubmit,invalid }">
        <form @submit.prevent="handleSubmit(ShowAddHirerDialog)">
            <div class="columns">
                <div class="column">
                    <b-field>
                        <ValidationProvider rules="required">
                            <b-field :type="{'is-danger':GetHirers.includes(hirer.name.toLowerCase())}" :message="{'Esta operação ja existe.':GetHirers.includes(hirer.name.toLowerCase())}" label-position="on-border">
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif has-text-weight-bold">Nome do empregador/operação</span>
                                </template>
                                <HirerAutocompleteInput v-model="hirer.name" :CanInsertHirer="false" />
                            </b-field>
                        </ValidationProvider>
                    </b-field>
                </div>
                <div class="column">
                    <ValidationProvider rules="required">
                        <b-field :type="{'is-danger':GetHirers.includes(hirer.alias.toLowerCase())}" :message="{'Este alias ja existe.':GetHirers.includes(hirer.alias.toLowerCase())}" label-position="on-border">
                            <template slot="label">
                                <span class="has-text-primary is-family-sans-serif has-text-weight-bold">Alias</span>
                            </template>
                            <b-input icon="users-cog" v-model="hirer.alias" expanded></b-input>
                        </b-field>
                    </ValidationProvider>
                </div>
                 <div class="column">
                    <ValidationProvider rules="required">                                             

                        <b-field label-position="on-border">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Tipo</span>
                        </template>
                        <b-select v-model="hirer.type" expanded :icon="typeHirer" icon-type="is-dark" >
                            <option value="aerostat"> Aerostato</option> 
                            <option value="offshore">Offshore</option>
                            <option value="tower">Torres</option>                            
                        </b-select>
                    </b-field>
                    </ValidationProvider>
                </div>
                <div class="column" v-if="hirer.type == 'tower'">
                    <b-field label-position="on-border" >
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Livestream</span>
                        </template>
                        <b-select v-model="hirer.livestream" expanded>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>                            
                        </b-select>                    
                    </b-field>                    
                </div>
                <div class="column" v-if="hirer.type == 'tower'">
                    <b-field label-position="on-border" >
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Controle de câmera</span>
                        </template>
                        <b-select v-model="hirer.camControl" expanded>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>                            
                        </b-select>                    
                    </b-field>                    
                </div>
                
            </div>
            
            <b-button native-type="submit" :disabled="invalid || GetHirers.includes(hirer.alias.toLowerCase()) || GetHirers.includes(hirer.name.toLowerCase())" expanded type="is-primary" outlined><span class="is-family-sans-serif">Confirmar</span></b-button>
           
        </form>
    </ValidationObserver>
</template>
<script>
import HirerAutocompleteInput from '@/components/management/HirerAutocompleteInput.vue'
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
extend('required', required)
export default {
    name: 'HirerInsert',
    components: {
        HirerAutocompleteInput,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            hirer: {
                name: '',
                alias: '',
                camControl:false,
                livestream:false
            },
        }
    },
    methods: {
        ShowAddHirerDialog() {
            this.hirer.livestream = Boolean(this.hirer.livestream)
            this.hirer.camControl = Boolean(this.hirer.camControl)
            this.$buefy.dialog.confirm({
                title: `Adicionar nova operação?`,
                message: '<p><b>Empregador/Operação: </b>' + this.hirer.name.toUpperCase() + '</p><p><b> Alias: </b>' + this.hirer.alias.toUpperCase() + '</p>',
                confirmText: 'Adicionar',
                cancelText: 'Cancelar',
                onConfirm: () => {
                    this.$store.dispatch('database/PushToDatabase', { path: 'hirers', object: this.hirer }).then(() => {
                            this.LaunchToast('Operação inserida com sucesso!', 'is-success')
                            this.hirer.name = ''
                            this.hirer.alias = ''
                        })
                        .catch(error => {
                            this.LaunchToast('Erro ao inserir operação: ' + error, 'is-danger')
                        })
                }
            })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000
            })
        }
    },
    computed: {
        GetHirers() {
            const arrayReturn = []
            this.$store.getters['database/GetHirers'].filter((option) => {
                arrayReturn.push(option.name.toLowerCase())
            })
            return arrayReturn
        },
        typeHirer(){
            let typeIcon =''
            switch (this.hirer.type) {
                case 'aerostat':
                    typeIcon= 'cloud'                   
                    break;
                case 'offshore':
                    typeIcon='ship'
                    break;
                case 'tower':                
                    typeIcon='broadcast-tower'
                    break;
            }
            return typeIcon

        }
    },
    created() {
        this.$store.dispatch('database/bindHirers')
    }

}
</script>