<template>
    <div class="columns is-multiline">
        <div class="column has-text-left">
            <b-field label-position="on-border" grouped group-multiline>
                <template slot="label">
                    <span class="has-text-primary is-family-sans-serif">Selecione a(s) data(s):</span>
                </template>
                
                <b-datepicker v-model="SelectedDate" :month-names="MonthNames" :day-names="DayNames" icon="calendar-alt" trap-focus multiple>
                </b-datepicker>
                <p class="control">
                    <b-button class="button is-primary" @click="ConfirmSelectedDates" expanded outlined><span class="is-family-sans-serif">Confirmar</span></b-button>
                </p>
                <p class="control">
                    <b-button class="button is-warning" @click="GeneratePDFReport" v-if="IsTableShowing" icon-right="file-pdf" expanded outlined><span class="is-family-sans-serif">Baixar relatório</span></b-button>
                </p>
                <p class="control">
                    <b-button class="button is-warning" @click="GenerateCsv" v-if="IsTableShowing" icon-left="file-csv"  expanded outlined>
                        <span class="is-family-sans-serif">CSV</span>
                    </b-button>
                </p>
            </b-field>
        </div>♥
        <div class="column is-full">
            <ReportTableUserPresence :acessLogObject="Logs" v-if="IsTableShowing" />
        </div>
    </div>
</template>
<style lang="scss" scope>
</style>
<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ReportTableUserPresence from '@/components/management/ReportTableUserPresence.vue'
export default {
    name: 'ReportUserPresence',
    components: {
        ReportTableUserPresence,
    },
    data() {
        return {
            SelectedDate: [], //Bound to datepicker component to hold user selected date to see logs.
            MonthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            DayNames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            IsTableShowing: false, //Used to whether shor or not table with logs.
            ChartData: {}, //Object with log data passed as props to chart component to render it.
            IsChartShowing: false, //Used to whether show or not chart with logs data.
            ReportData: { highest: {}, users: [], total: 0 }, //Object used to store information about logs (e.g. who spent more time online).
            Logs: [],
            DistinctUsersInSelectedLogs: [],
        }
    },
    watch: {
        Logs: {
            handler(logs) { if (logs) this.IsTableShowing = true }
        },
    },
    methods: {
        //Called when user clicks on confirm button after picking dates on datepicker component to show logs.
        ConfirmSelectedDates() {
            this.Logs = [] //Clear Logs piece of state so they don't stack with previous selections.
            this.DistinctUsersInSelectedLogs = [] //Clear DistinctUsersInSelectedLogs piece of state so they don't stack with previous selections.
            this.SelectedDate.sort((dateOne, dateTwo) => { return dateOne - dateTwo }) //Sort array so dates are in a chronological order.            
            this.SelectedDate.forEach(date => {
                this.$store.dispatch('database/GetFromDatabase', {
                    path: 'actions-log/' + date.toLocaleDateString('pt-br').split('/').join('-'),
                    returnSnapshotVal: false
                }).then(snapshot => {
                    snapshot.forEach(childSnapshot => {
                        this.Logs.push(childSnapshot.val())
                        if (!this.DistinctUsersInSelectedLogs.includes(childSnapshot.val().LogObject.user)) {
                            this.DistinctUsersInSelectedLogs.push(childSnapshot.val().LogObject.user)
                        }
                    })
                })
            })
        },
        //Used to convert the log table data which is shaped for a table component from Buefy to a structure used in jsPDF-AutoTable.
        ConvertLogTableToJsPDF(logs) {
            let rows = [] //Create empty array to house newly created rows.
            logs.forEach(cell => { //For each object in SelectedDateLogs.
                let temporaryRows = [ //Fill temporary rows with data from inside the object on this iteration.
                    cell.LogObject.user, //User property from LogObject.
                    cell.LogObject.action, //Action property from LogObject.
                    cell.LogObject.region, //Region property from LogObject.
                    cell.LogObject.city, //City property from LogObject.
                    cell.LogObject.ip, //Ip property from LogObject.
                    new Date(cell.LogObject.hour).toLocaleDateString('pt-br'), //Date property from LogObject.
                    new Date(cell.LogObject.hour).toLocaleTimeString('pt-br') //Hour property from LogObject.
                ]
                rows.push(temporaryRows) //Push this temporary row to definitive rows array.
            })
            return rows //Return properly structured rows.
        },
        //Used to create a table shaped in jsPDF-AutoTable's structure using data on ReportData piece of state. Table is used on generated PDF report later.
        //Receive a jsPDF instance as a parameter so it creates the table on it.
        CreateReportTable(doc) {
            let tableBody = [] //Create empty array to house table's body.
            this.ReportData.users.forEach(item => { //For each user in ReportData piece of state.
                let temporaryArray = [] //Create empty array to house data for each user iteration.
                temporaryArray.push(item.user, Math.floor(item.timeSpent / 60) + ':' + item.timeSpent % 60, item.differentUserAccessess) //Push user data to newly created array.
                tableBody.push(temporaryArray) //Push newly created array with user data to tableBody array, creating an array of arrays.
            })
            doc.autoTable({ //Use jsPDF-AutoTable's method to created a new table using data from tableBody variable.
                startY: 158, //Table starting point on Y axis on PDF page.
                tableWidth: 'auto', //Table width.
                theme: 'striped', //Table theme.
                styles: { fontSize: '15', halign: 'left' }, //Table font size.
                columnStyles: { 0: { cellWidth: 50 } },
                head: [ //Table header.
                    [
                        'Usuário', 'Tempo acessado\n(horas e minutos)', 'Acessos diferentes'
                    ]
                ],
                body: tableBody, //Table body usigin tableBody variable.
            })
        },
        //Used to fill ReportData piece of state with information acquired from logs such as users' time spent online, who spend most time online, etc.
        //HARPIA saves epoch from when user is online and when user is offline. Here these epochs are grouped together in arrays and it's possible to calculate time.
        //Information generated here is used on PDF report later.
        FillReportChartData() {
            this.ReportData = { highest: {}, users: [], total: 0 }
            this.ChartData = {}
            return new Promise((resolve, reject) => {
                
                let totalTimeSpent = 0 //Initiate counter for the TOTAL TIME SPENT on HARPIA.
                let totalUserTimeSpent = [] //Create empty array to house total time spent BY EACH USER on HARPIA. It's an array so it can be used on the chart later.
                let highestTimeSpent = 0 //Initiate counter for THE HIGHEST TIME SPENT on HARPIA. Used to figure out who spent most time online.                
                this.DistinctUsersInSelectedLogs.forEach(user => { //For each distinct user on selected logs.
                    let userTimeSpent = 0 //Initiate counter for current iteration user time spent on HARPIA.
                    let differentUserAccessess = 0 //Initiate counter for each different current iteration user access to HARPIA.
                    let username = user.substring(0, user.lastIndexOf('@'))
                    let filter = this.Logs.filter(object => { //Filter log array to get only entries that are from current iteration user and are either online/offline.                       
                        return object.LogObject.user === user && (object.LogObject.action === 'online' || object.LogObject.action === 'offline') //Return filtered entries.
                    })
                    
                    filter.forEach((item, index, array) => { //For each entry on filtered array created above.
                        if (array[index] === array[array.length - 1] && array[index].LogObject.action === 'online') { filter.pop() } //If last entry's action is 'online', it is discarded as it means the user currently online on HARPIA.
                    })
                    filter.forEach((item, index, array) => { //For each entry on filtered array created above.
                        if (array[index] === array[0] && array[index].LogObject.action === 'offline') { filter.shift() } //If last entry's action is 'online', it is discarded as it means the user currently online on HARPIA.
                    })
                    let arrayChunks = this.ChunkArray(filter, 2) //Chunk array of online/offline entries into lesser arrays of two entries each (e.g. [[1,2], [1,2], [1,2]]).
                    
                    arrayChunks.forEach(chunk => { //For each chunk of arrayChunks created above.
                        if (chunk.length > 1) { //If chunk length is more than one it means online/offline times were save correctly if there were no erros on HARPIA.
                            let sessionTimeSpent = this.GetDifferenceInMinutesFromDates(new Date(chunk[1].LogObject.hour), new Date(chunk[0].LogObject.hour)) //Calculate time spent on this chunk.
                            userTimeSpent += sessionTimeSpent //Increment current iteration user time spent.
                            totalTimeSpent += sessionTimeSpent //Increment total time spent on HARPIA.
                            differentUserAccessess += 1 //Increment count on different accessess from current iteration user.
                        }
                    })
                    totalUserTimeSpent.push(Math.round(userTimeSpent / 60)) //Push current iteration user time spent to array with all users' time spent.
                    if (userTimeSpent > highestTimeSpent) { //If current iteration user time spent is greater than highest time spent.
                        highestTimeSpent = userTimeSpent //Highest time spent is current iteration user time spent.
                        this.ReportData.highest = { user: username, timeSpent: userTimeSpent } //Assign to ReportData.highest key data about the user with the most time spent.
                    }
                    this.ReportData.users.push({ user: username, timeSpent: userTimeSpent, differentUserAccessess: differentUserAccessess }) //Push to ReportData.users key spent time data about current iteration user.
                })
                this.ChartData['labels'] = this.GetDistinctUsersInSelectedLogs //Push to ChartData.labels key every distinct user in selected dates logs.
                this.ChartData['datasets'] = { data: totalUserTimeSpent } //Push to ChartData.datasets key an object containing the array with total time spent BY EACH USER.
                this.ReportData.total = totalTimeSpent //Push to ReportData.total key the TOTAL TIME SPENT on HARPIA.
                // this.IsChartShowing = true //Show chart with information on HARPIA usage.
                // console.log('Horas: ' + Math.floor(totalTimeSpent / 60) + '. Minutos: ' + totalTimeSpent % 60 + '.')
                totalTimeSpent > 0 ? resolve() : reject()
            })
        },
        //Used to create an array of arrays of size N. Called by FillReportChartData() to chunk array online/offline entries into an array of arrays of size two each (e.g. [[1,2], [1,2], [1,2]]). This way, entries are separated in one online/offline pair per chunk if HARPIA registered user presence correctly.
        //Receive as parameter an array and a number representing desired size of chunks.
        ChunkArray(array, parts) {
            let chunks = [] //Create empty array to house chunks.
            let i = 0 //Initiates counter for while loop to iterate over original array.
            while (i < array.length) { //While counter is lesser than original array length.
                chunks.push(array.slice(i, i += parts)) //Push to chunks array a slice from the original array, containing entries from index i until i += parts.
            }
            return chunks //Return array of chunks.
        },
        //Used to calculate the difference in minutes between to Date objects.
        //Receive as parameters two Date objects.
        GetDifferenceInMinutesFromDates(dateOne, dateTwo) {
            let diff = (dateOne.getTime() - dateTwo.getTime()) / 1000 //Calculate difference in minutes between epoch of two Date objects and stores in variable.
            diff /= 60 //Divide difference by 60.
            return Math.abs(Math.round(diff)) //Returns absolute rounded difference.
        },
        //Used to generate a PDF report using information created in the above methods about HARPIA usage.
        GeneratePDFReport() {
            this.FillReportChartData()
                .then(() => {
                    
                    const doc = new jsPDF() //Instantiate new JsPDF document.
                    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth() //Save PDF page size to variable so it is easier to center things.
                    const totalTimeSpentText = Math.floor(this.ReportData.total / 60) + ' horas e ' + this.ReportData.total % 60 + ' minutos' //Text about total time spent.
                    const timePeriodText = 'Relatório de acessos ao HARPIA no período: ' + this.SelectedDate[0].toLocaleDateString('pt-br') + ' - ' + this.SelectedDate[this.SelectedDate.length - 1].toLocaleDateString('pt-br') //Text about period of the generated PDF report to put on header.
                    const highestTimeSpentUserText = '(' + Math.floor(this.ReportData.highest.timeSpent / 60) + ' horas e ' + Math.floor(this.ReportData.highest.timeSpent % 60) + ' minutos)' //Text about highest time spent by user.

                    doc.setFontSize(15) //Set font size of next lines.
                    doc.text(timePeriodText, pageWidth / 2, 10, 'center') //Print text.
                    doc.setFontSize(40) //Set font size of next lines.
                    doc.setFontStyle('bold') //Set font weight of next lines.
                    doc.text(totalTimeSpentText, pageWidth / 2, 45, 'center')
                    doc.setFontSize(20) //Set font size of next lines.
                    doc.text('totais acessados', pageWidth / 2, 54, 'center')
                    doc.setFontSize(30) //Set font size of next lines.
                    doc.text(this.ReportData.highest.user, pageWidth / 2, 92, 'center') //Print text.
                    doc.setFontSize(20) //Set font size of next lines.
                    doc.text('acessou por mais tempo', pageWidth / 2, 101, 'center') //Print text.
                    doc.setFontStyle('normal') //Set font size of next lines.
                    doc.text(highestTimeSpentUserText, pageWidth / 2, 108, 'center') //Print text.
                    this.CreateReportTable(doc) //Call CreateReportTable to generate a jsPDF usable table.
                    doc.addPage('a4', 'p') //Add new A4 page.

                    //CHART WIP
                    // const canvasEle = document.getElementById('chart') //Get chart canvas from DOM.
                    // const url_base64 = canvasEle.toDataURL('image/png', 1) //Convert canvas to PNG.
                    // console.log(url_base64)
                    // doc.addImage(url_base64, 'PNG', 2, 138, 205, 150, null, 'SLOW') //Add canvas image to document.
                    // doc.addPage('a4', 'p') //Add new A4 page.

                    const columns = ['Usuário', 'Ação', 'Região', 'Cidade', 'IP', 'Data', 'Hora'] //Initiates PDF table headers.
                    const rows = this.ConvertLogTableToJsPDF(this.Logs) //Initiates PDF table rows.
                    doc.setFontSize(12) //Set font size of next lines.
                    doc.setFontStyle('bold') //Set font weight of next lines.
                    doc.autoTable(columns, rows, {
                        rowPageBreak:'avoid',
                        startY: 10,
                        tableWidth: 'auto',
                        theme: 'grid',
                        margin: { left: 10 },
                        styles: {
                            overflow: 'linebreak',
                            cellWidth: 'wrap'                            
                        },                     
                        columnStyles: {    
                            0:{
                                cellWidth:55
                            },
                            2: {                                
                                cellWidth: 20
                            },                        
                            3: {                                
                                cellWidth: 'auto'
                            },
                            4: {                                
                                cellWidth: 37
                            }
                        }
                    }) //Transforms given columns and rows array in a table.
                    doc.save(new Date().toLocaleString('pt-br') + '-HARPIA-LOG' + '.pdf') //Saves created table to PDF.
                })
                .catch(() => {
                    this.LaunchToast('Não há dados suficientes para gerar relatório!', 'is-danger')
                })
        },
        GenerateCsv(){
            let initialDate = new Date(this.SelectedDate[0]).toLocaleDateString('pt-BR')
            let finalDate = new Date(this.SelectedDate[this.SelectedDate.length - 1]).toLocaleDateString('pt-BR')
            var csv = 'Usuario, Região, Cidade, IP, Ação, Data, Hora\n';
            this.Logs.forEach(row => {
                csv+=row.LogObject.user;
                csv+=','+row.LogObject.region;                        
                csv+=','+row.LogObject.city;
                csv+=','+row.LogObject.ip;
                csv+=','+row.LogObject.action;
                csv+=','+new Date(row.LogObject.hour).toLocaleDateString('pt-BR');
                csv+=','+new Date(row.LogObject.hour).toLocaleTimeString('pt-BR'); 
                csv += '\n';                     
            });      
            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = `Dados Acesso usuários ${initialDate} - ${finalDate}.csv`;
            hiddenElement.click();
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000
            })
        }
    },
}
</script>