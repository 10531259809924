<template>
    <div class="columns">
        <div class="column">
            <b-table :data="users" striped focusable paginated :per-page="5">
                <b-table-column cellClass="has-text-left" field="name" label="Usuário" v-slot="props" searchable>
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column cellClass="has-text-left" field="email" label="Email" v-slot="props" searchable>
                    {{ props.row.email }}
                </b-table-column>
                <b-table-column cellClass="has-text-left" field="flagString" label="Operação" v-slot="props" searchable>
                    {{ props.row.flagString }}
                </b-table-column>
                <b-table-column field="editUser" v-slot="props">
                    <b-button outlined type="is-primary" @click="selectUser(props.row)">Editar</b-button>
                </b-table-column>
            </b-table>
        </div>
        <div class="column" v-if="AlteredUserObject.Name">
            <div class="columns is-multiline">
                <div class="column is-full">
                    <div class="has-text-left box">
                        <p><span class="has-text-weight-bold title"> {{AlteredUserObject.Name}} </span></p>
                        <p><span class="has-text-weight-bold subtitle"> {{AlteredUserObject.Email}} </span></p>
                    </div>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Empresa</span>
                        </template>
                        <b-input v-model="AlteredUserObject.Company" icon="key" expanded></b-input>
                    </b-field>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Operação</span>
                        </template>
                        <HirerAutocompleteTagInput v-model="AlteredUserObject.Flag" :UserFormerHirers="AlteredUserObject.Flag" />
                    </b-field>
                </div>
                <div class="column is-full">
                    <div class="columns">
                        <div class="column has-text-left is-flex is-flex-direction-column">
                            <span class="has-text-weight-bold has-text-primary">Permissões</span>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.livestreamview"><span class="has-text-weight-semibold">Transmissão ao vivo</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.georefview"><span class="has-text-weight-semibold">Georreferenciamento</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.cameracontrol"><span class="has-text-weight-semibold">Controle da câmera</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.alertsview"><span class="has-text-weight-semibold">Visualização de alertas do SAURON</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.alertsremoving"><span class="has-text-weight-semibold">Exclusão de alertas do SAURON</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.assistedsauron"><span class="has-text-weight-semibold">SAURON assistido</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.sauronassistant"><span class="has-text-weight-semibold">SAURON Auxiliar</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.mordorondemand"><span class="has-text-weight-semibold">MORDOR sob demanda</span></b-switch>
                            <b-switch class="py-1" :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.newmordor"><span class="has-text-weight-semibold">New MORDOR</span></b-switch>
                        </div>
                        <div class="column has-text-left is-flex is-flex-direction-column" v-if="AlteredUserObject.Permissions.mordorondemand">
                            <span class="has-text-weight-bold has-text-primary">Permissões - MORDOR on demand</span>
                            <b-switch :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.mordorReqStream"><span class="has-text-weight-semibold">MORDOR on demand - Streaming</span></b-switch>
                            <b-switch :rounded="false" type="is-primary" v-model="AlteredUserObject.Permissions.mordorReqFrame"><span class="has-text-weight-semibold">MORDOR on demand - Frames</span></b-switch>
                        </div>
                        <div class="column has-text-left is-flex is-flex-direction-column">
                            <span class="has-text-weight-bold has-text-primary">Papel</span>
                            <b-radio class="py-1" v-model="AlteredUserObject.Role" :disabled="AlteredUserObject.Role==='admin'" native-value="admin"> <span class="has-text-weight-semibold">Administrador</span></b-radio>
                            <b-radio class="py-1" v-model="AlteredUserObject.Role" :disabled="AlteredUserObject.Role==='admin'" native-value="supervisor"> <span class="has-text-weight-semibold">Supervisor</span></b-radio>
                            <b-radio class="py-1" v-model="AlteredUserObject.Role" :disabled="AlteredUserObject.Role==='admin'" native-value="operator"> <span class="has-text-weight-semibold">Operador</span></b-radio>
                            <b-radio class="py-1" v-model="AlteredUserObject.Role" :disabled="AlteredUserObject.Role==='admin'" native-value="client"> <span class="has-text-weight-semibold">Cliente</span></b-radio>
                        </div>
                    </div>
                    <b-field>
                        <b-button type="is-primary" @click="ConfirmEditUser" :loading="IsConfirmButtonSpinning" expanded outlined><span class="is-family-sans-serif">Confirmar alterações</span></b-button>
                    </b-field>
                    <b-field v-if="AlteredUserObject.Role!='admin'">
                        <b-button type="is-danger" @click="confirmDelete" :loading="IsConfirmButtonSpinning" expanded outlined><span class="is-family-sans-serif">Excluir Usuario</span></b-button>
                    </b-field>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import HirerAutocompleteTagInput from '@/components/management/HirerAutocompleteTagInput.vue'
export default {
    name: 'UserModifyC',
    components: {
        HirerAutocompleteTagInput
    },
    data() {
        return {
            showDetailIcon: false,
            defaultOpenedDetails: [1],
            users: '',
            columns: [
                { field: 'name', label: 'Nome' },
                { field: 'email', label: 'Email' },
                { field: 'flagString', label: 'Operação' }
            ],
            AlteredUserObject: {
                Name: '',
                Email: '',
                Company: '',
                Flag: [],
                Role: '',
                Permissions: {
                    livestreamview: false,
                    georefview: false,
                    cameracontrol: false,
                    alertsview: false,
                    alertsremoving: false,
                    assistedsauron: false,
                    mordorondemand: false,
                    mordorReqStream: false,
                    mordorReqFrame: false,
                },
                Uid: ''
            },
            FilteredHirers: [],
            IsConfirmButtonSpinning: false
        }
    },
    computed: {
        GetUsers() {
            return this.$store.getters['database/GetUsers']
        },
        GetHirers() {
            return this.$store.getters['database/GetHirers']
        },
    },
    watch: {
        GetUsers: {
            handler(users) {
                users.forEach(user => {
                    user.flagString = user.flag.map(flag => { return flag.alias }).join(', ')
                })
            }
        }
    },
    methods: {
        selectUser(userSelected) {
            this.AlteredUserObject = {
                Name: userSelected.name,
                Email: userSelected.email,
                Company: userSelected.company,
                Flag: userSelected.flag,
                Role: userSelected.role,
                Permissions: userSelected.permissions,
                Uid: userSelected.uid
            }

        },
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Excluir conta',
                message: 'Tem certeza que deseja excluir a conta de ' + this.AlteredUserObject.Name.toUpperCase() +
                    '?',
                confirmText: 'Sim',
                cancelText: 'Não',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.DeleteUser()
            })
        },
        DeleteUser() {
            this.IsConfirmButtonSpinning = true
            this.$store.dispatch('user/DeleteUser', this.AlteredUserObject.Uid)
                .then(() => {
                    this.LaunchToast("Usuário " + this.AlteredUserObject.Name + " foi excluído.", "is-success")
                    this.IsConfirmButtonSpinning = false
                })
                .catch(error => {
                    this.IsConfirmButtonSpinning = false
                    this.LaunchToast(error, "is-danger")
                })
        },
        ConfirmEditUser() {
            this.IsConfirmButtonSpinning = true
            this.$store.dispatch('user/EditUser', this.AlteredUserObject)
                .then(() => {
                    this.IsConfirmButtonSpinning = false
                    this.LaunchToast('Usuário alterado com sucesso!', 'is-success')
                })
                .catch(error => { this.LaunchToast('Erro ao alterar usuário: ' + error, 'is-danger') })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000
            })
        }
    },
    created() {
        this.$store.dispatch('database/bindUsers')
        this.FilteredHirers = this.$store.getters['database/GetHirers']
        this.users = this.GetUsers
        // console.log(this.GetUsers)
        // for (let user in this.users) { console.log(this.users[user]) }
    }
}
</script>