<template>
    <div class="columns">
        <div class="column is-full">
            <b-steps animated size="is-small" :has-navigation="true">
                <b-step-item label="Windy" icon="account" clickable :type="{'is-success': WeatherDataObject.windy.windSpeed && WeatherDataObject.windy.gustSpeed,'is-danger': !WeatherDataObject.windy.windSpeed || !WeatherDataObject.windy.gustSpeed}">
                    <p class="has-text-centered">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.windy.com/" class="is-size-6 has-text-weight-bold">{{$t('opReport.windyGo')}}</a>
                        <b-icon pack="fas" icon="external-link-alt" size="is-small" type="is-primary">
                        </b-icon>
                    </p>
                    <div class="column">
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.windSpeed')}} </span>
                                </template>
                                <b-input v-model="WeatherDataObject.windy.windSpeed" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                        <br>
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.gusts')}}</span>
                                </template>
                                <b-input v-model="WeatherDataObject.windy.gustSpeed" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                    </div>
                </b-step-item>
                <b-step-item label="TUPAN" icon="account-plus" clickable :type="{'is-success':  WeatherDataObject.tupan,'is-danger': !WeatherDataObject.tupan}">
                    <p class="has-text-centered">
                        <a target="_blank" rel="noopener noreferrer" href="https://s3-sa-east-1.amazonaws.com/tupan/index.html" class="is-size-6 has-text-weight-bold">{{$t('opReport.tupanGo')}}</a>
                        <b-icon pack="fas" icon="external-link-alt" size="is-small" type="is-primary">
                        </b-icon>
                    </p>
                    <div class="column">
                        <b-radio v-model="WeatherDataObject.tupan" native-value="voo-liberado"> {{$t('opReport.tupanStatus.flyAllow')}}</b-radio>
                        <b-radio v-model="WeatherDataObject.tupan" native-value="baixe-balao"> {{$t('opReport.tupanStatus.recoverAerostat')}} </b-radio>
                        <b-radio v-model="WeatherDataObject.tupan" native-value="sem-informacao"> {{$t('opReport.tupanStatus.noInfo')}} </b-radio>
                    </div>
                </b-step-item>
                <b-step-item label="Local Weather Station" icon="account-plus" clickable :type="{'is-success':  WeatherDataObject.localStation.windSpeed && WeatherDataObject.localStation.atmosphericPressure,'is-danger': !WeatherDataObject.localStation.windSpeed || !WeatherDataObject.localStation.atmosphericPressure}">
                    <p class="has-text-centered">
                        <a target="_blank" rel="noopener noreferrer" href="http://www.weatherlink.com/" class="is-size-6 has-text-weight-bold">{{$t('opReport.codebaGo')}} </a>
                        <b-icon pack="fas" icon="external-link-alt" size="is-small" type="is-primary">
                        </b-icon>
                    </p>
                    <div class="column">
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.codebaStatus.windSpeed')}} </span>
                                </template>
                                <b-input v-model="WeatherDataObject.localStation.windSpeed" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                        <br>
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.codebaStatus.atmosphericPressure')}}  </span>
                                </template>
                                <b-input v-model="WeatherDataObject.localStation.atmosphericPressure" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                        <br>
                        <b-field label-position="on-border" grouped group-multiline>
                            <template slot="label">
                                <span class="has-text-primary is-family-sans-serif">{{$t('opReport.codebaStatus.windDirection')}}</span>
                            </template>
                            <b-select v-model="WeatherDataObject.localStation.angle" expanded>
                                <option value="n">N</option>
                                <option value="nne">NNE</option>
                                <option value="ne">NE</option>
                                <option value="one">ONE</option>
                                <option value="e">E</option>
                                <option value="ese">OSE</option>
                                <option value="se">SE</option>
                                <option value="sse">SSE</option>
                                <option value="s">S</option>
                                <option value="ssw">SSW</option>
                                <option value="sw">SW</option>
                                <option value="wsw">WSW</option>
                                <option value="w">W</option>
                                <option value="wnw">WNW</option>
                                <option value="nw">NW</option>
                                <option value="nnw">NNW</option>
                            </b-select>
                            <b-field>
                                <b-field>
                                    <b-button type="is-primary" @click="IsImageModalActive = true" expanded>
                                       {{$t('opReport.codebaStatus.windRose')}}
                                    </b-button>
                                </b-field>
                            </b-field>
                        </b-field>
                    </div>
                </b-step-item>
                <b-step-item label="Anemometer" icon="account-plus" clickable :type="{'is-success':  WeatherDataObject.local.windSpeed,'is-danger': !WeatherDataObject.local.windSpeed}">
                    <div class="column">
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.codebaStatus.windSpeed')}}:</span>
                                </template>
                                <b-input v-model="WeatherDataObject.local.windSpeed" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                    </div>
                </b-step-item>
                <b-step-item label="Cemaden" icon="account-plus" clickable :type="{'is-success':  WeatherDataObject.cemaden.clouds,'is-danger': !WeatherDataObject.cemaden.clouds}">
                    <p class="has-text-centered">
                        <a target="_blank" rel="noopener noreferrer" href="http://www.cemaden.gov.br/mapainterativo/#" class="is-size-6 has-text-weight-bold">{{$t('opReport.cemadenGo')}} </a>
                        <b-icon pack="fas" icon="external-link-alt" size="is-small" type="is-primary">
                        </b-icon>
                    </p>
                    <div class="column">
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.reflectivity')}} </span>
                                </template>
                                <b-input v-model="WeatherDataObject.cemaden.clouds" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                    </div>
                </b-step-item>
                <b-step-item label="Geocam" icon="account-plus" clickable :type="{'is-success':  WeatherDataObject.geocam.cableInclination && WeatherDataObject.geocam.aerostatAltitude,'is-danger': !WeatherDataObject.geocam.cableInclination || !WeatherDataObject.geocam.aerostatAltitude}">
                    <div class="column">
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.geocamStatus.cableSlope')}}</span>
                                </template>
                                <b-input v-model="WeatherDataObject.geocam.cableInclination" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                        <br>
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.geocamStatus.aerostatAltitude')}}</span>
                                </template>
                                <b-input v-model="WeatherDataObject.geocam.aerostatAltitude" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                    </div>
                </b-step-item>
                <b-step-item label="Lightining Detector" icon="account-plus" clickable :type="{'is-success':  WeatherDataObject.lightningDetector.distance && WeatherDataObject.lightningDetector.lightning,'is-danger': !WeatherDataObject.lightningDetector.distance || !WeatherDataObject.lightningDetector.lightning}">
                    <div class="column">
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.distance')}}:</span>
                                </template>
                                <b-input v-model="WeatherDataObject.lightningDetector.distance" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                        <br>
                        <ValidationProvider v-slot="{errors}" :rules="{regex:/^[0-9.]+$/}">
                            <b-field label-position="on-border" :type=" {'is-danger':errors[0]}" :message='errors[0]'>
                                <template slot="label">
                                    <span class="has-text-primary is-family-sans-serif">{{$t('opReport.eletricalField')}}</span>
                                </template>
                                <b-input v-model="WeatherDataObject.lightningDetector.lightning" expanded></b-input>
                            </b-field>
                        </ValidationProvider>
                    </div>
                </b-step-item>
                <b-step-item label="Finish" type="is-success" clickable>
                    <div class="column" align="left">
                        <dl>
                            <dt>Windy</dt>
                            <dd>- {{$t('opReport.reportFinish.windy.windSpeed')}}: {{WeatherDataObject.windy.windSpeed}}</dd>
                            <dd>- {{$t('opReport.reportFinish.windy.gusts')}}: {{WeatherDataObject.windy.gustSpeed}}</dd>
                            <dt>TUPAN</dt>
                            <dd v-if="WeatherDataObject.tupan === 'voo-liberado'">- Status: {{$t('opReport.reportFinish.tupan.flightReleased')}}:</dd>
                            <dd v-else-if="WeatherDataObject.tupan === 'baixe-balao'">- Status: {{$t('opReport.reportFinish.tupan.downBallon')}}</dd>
                            <dd v-else>- Status: {{$t('opReport.reportFinish.tupanStatus.noInfo')}}</dd>
                            <dt>{{$t('opReport.anemometer')}}</dt>
                            <dd>- {{$t('opReport.windSpeed')}}: {{WeatherDataObject.localStation.windSpeed}}</dd>
                            <dd>- {{$t('opReport.codebaStatus.atmosphericPressure')}}: {{WeatherDataObject.localStation.atmosphericPressure}}</dd>
                            <dt>{{$t('opReport.anemometer')}}</dt>
                            <dd>-{{$t('opReport.windSpeed')}}: {{WeatherDataObject.local.windSpeed}}</dd>
                            <dt>Cemaden</dt>
                            <dd>- {{$t('opReport.reportFinish.cemaden.cloudReflex')}}: {{WeatherDataObject.cemaden.clouds}}</dd>
                            <dt>Geocam</dt>
                            <dd>- {{$t('opReport.reportFinish.geocam.cableSlope')}}: {{WeatherDataObject.geocam.cableInclination}}</dd>
                            <dd>- {{$t('opReport.reportFinish.geocam.aerostatAltitude')}}: {{WeatherDataObject.geocam.aerostatAltitude}}</dd>
                        </dl>
                    </div>
                </b-step-item>
            </b-steps>
        </div>
        <b-modal v-model="IsImageModalActive">
            <p class="image is-1by1">
                <img src="@/assets/wind-rose.png">
            </p>
        </b-modal>
    </div>
</template>
<style scoped>
dt {
    font-size: 1em;
    font-weight: bolder;
}

dd {
    font-size: 1em;
    text-indent: 0.5em;
    white-space: nowrap;
}
</style>
<script>
import { regex } from 'vee-validate/dist/rules'
import { ValidationProvider, extend } from 'vee-validate'
extend('regex', { ...regex, message: 'Utilize apenas números e ponto "." .' })
export default {
    name: "InsertActivity",
    components: {
        ValidationProvider,
    },
    props: {
        AerostatStatus: Array
    },
    data() {
        return {
            IsConfirmButtonSpinning: false,
            IsImageModalActive: false,
            WeatherDataObject: {
                windy: { windSpeed: '', gustSpeed: '', angle: '' },
                tupan: null,
                localStation: { windSpeed: '', atmosphericPressure: '', angle: '' },
                local: { windSpeed: '' },
                cemaden: { clouds: '' },
                geocam: { cableInclination: '', aerostatAltitude: '' },
                lightningDetector: { distance: '', lightning: '' }
            },
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetWeatherDataObject() {
            return this.WeatherDataObject
        }
    },
    watch: {
        GetWeatherDataObject: {
            handler(weatherDataObject) {
                this.$emit('input', weatherDataObject)
            },
            deep: true
        }
    },
    methods: {
        ChangeImageUploadMethod(method) {
            this.ImageUploadMethod = method
        },
        PushWeatherDataObjectToDatabase() {
            this.WeatherDataObject.aerostatStatus = { isAnchored: true, altitude: 0 }
            this.$store.dispatch('database/PushWeatherDataToDatabase', { flag: this.GetCurrentUser.activeFlag, weatherDataObject: this.WeatherDataObject })
        }
    }
}
</script>