<template>
    <div class="columns is-multiline">
        <div class="column has-text-left">
            <b-field label-position="on-border" grouped group-multiline>
                <template slot="label">
                    <span class="has-text-primary is-family-sans-serif">Selecione a(s) data(s):</span>
                </template>
                <b-datepicker v-model="SelectedDate" icon="calendar-alt" trap-focus multiple></b-datepicker>
                <p class="control">
                    <b-button class="button is-primary" @click="ConfirmSelectedDates" expanded outlined>
                        <span class="is-family-sans-serif">Confirmar</span>
                    </b-button>
                  
                </p>
                <p class="control">
                       <b-button class="button is-warning" @click="GenerateCsv" icon-left="file-csv"  expanded outlined>
                        <span class="is-family-sans-serif">CSV</span>
                    </b-button>
                    
                </p>
            </b-field>
            <div class="resumo ml-5" v-if="showTable">
                <p class="is-size-4 has-text-weight-bold is-uppercase">Resumo</p>  
                <p class="has-text-weight-semibold">Login/Logout: <span class="has-text-weight-medium  ">{{total_logins}}</span></p>
                <p class="has-text-weight-semibold">Sessões de Streaming: <span class="has-text-weight-medium  ">{{total_stream_sessoes}}</span></p>
                <p class="has-text-weight-semibold">Tempo de Streamings: <span class="has-text-weight-medium  ">{{MilisecondsConverter(total_stream_times)}}</span></p>
                <p class="has-text-weight-semibold">Gravações solicitadas: <span class="has-text-weight-medium  ">{{total_recordings}}</span></p>
                <p class="has-text-weight-semibold">Total de interações: <span class="has-text-weight-medium  ">{{total_logins+total_stream_sessoes+total_recordings}}</span></p>
            </div>
            <div class="columns" v-if="showTable">               
                <b-table class="column is-11 mt-5 mr-5 ml-5" :data='CalculateActions'  v-if="CalculateActions.length && showTable" >
                    <b-table-column field="user" label="Usuario" v-slot="props">
                        {{props.row.user}}
                    </b-table-column>
                     <b-table-column field="loginsLogout" label="Login/Logout" v-slot="props" centered>
                        {{props.row.loginsLogout?props.row.loginsLogout:0}}
                    </b-table-column>
                    <b-table-column field="sessions" label="Sessões de Streaming" v-slot="props" centered>
                        {{props.row.sessions?props.row.sessions:0}}
                    </b-table-column>
                    <b-table-column field="totalTime" label="Tempo de Streaming" v-slot="props" centered>
                        {{props.row.totalTime?MilisecondsConverter(props.row.totalTime):0}}
                    </b-table-column>
                    <b-table-column field="requestsRecordings" label="Gravações" v-slot="props" centered>
                        {{props.row.requestsRecordings?props.row.requestsRecordings:0}}
                    </b-table-column>
                    <b-table-column label="Total" v-slot="props" centered>
                        {{(props.row.loginsLogout?props.row.loginsLogout:0)
                        +(props.row.sessions?props.row.sessions:0)
                        +(props.row.requestsRecordings?props.row.requestsRecordings:0)}}
                    </b-table-column>
                </b-table>

                <p id="empty" class="is-size-2 has-text-weight-semibold column mt-5" v-else >
                    Sem dados
                </p>  
              </div>
        </div>
    </div>
</template>
<style lang="scss" scope>
#empty{
    color:darkblue;
    text-align: center;
}

</style>
<script>
export default {
    name: 'MordorOperationReport',
    data() {
        return {
            isEmpty: false,
            SelectedDate: [], //Bound to datepicker component to hold user selected date to see logs.           
            OperationReports: [],            
            usersCompany:'',
            requestsRecordings:[],
            streamingUsed:[],            
            countersReport:[],
            counterStreaming:[],
            counterLogs:[],
            showTable:false,
            total_stream_sessoes: 0,  // somar quantas sessoes de stream houveram
            total_stream_times: 0,  // variavel para somar o tempo total q ficou stremando
            total_logins: 0,  // variavel para somar a quantidade total de logins realizados
            total_recordings: 0 //  variavel para somar a quantidade total de gravacoes requisitadas
        }
    },
    computed: {  //computed fica atualizando em tempo real
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        CalculateActions(){
                let soma_stream_sessoes = 0  // somar quantas sessoes de stream houveram
                let soma_stream_times = 0  // variavel para somar o tempo total q ficou stremando
                let somatoria_de_logins = 0  // variavel para somar a quantidade total de logins realizados
                let soma_req_recordings = 0 //  variavel para somar a quantidade total de gravacoes requisitadas
                let calcActions=[]  // lista de dados a retornar
                let total = 1;  // se o usuario existir no requests já significa ao menos 1 login
                for (let i = 0; i <  this.countersReport.length; i++) {                
                    if (i <  this.countersReport.length - 1 &&  this.countersReport[i] ==  this.countersReport[i + 1]) {
                        total++;
                    } else {
                        somatoria_de_logins += total;  // no minimo 1 login foi feito
                        calcActions.push({ user:  this.countersReport[i], loginsLogout: total });
                        total = 1;
                    }
                }
                
            
                total = 1  // quantas vezes aquele usuario stream-ou
                let streams=0  // tempo de stream é calculado usando end -start 
                for (let i = 0; i <  this.counterStreaming.length; i++) { 
                    if (i <  this.counterStreaming.length - 1 &&  this.counterStreaming[i].email ==  this.counterStreaming[i + 1].email) {
                        streams += (this.counterStreaming[i].endTime - this.counterStreaming[i].startTime)
                        total++;
                    }
                    else {  // salva e passa pro proximo usuario
                        streams += this.counterStreaming[i].endTime - this.counterStreaming[i].startTime
                        calcActions.push({ user:  this.counterStreaming[i].email, totalTime: streams, sessions:total });
                        soma_stream_sessoes += total  
                        soma_stream_times += streams
                        total = 1;
                        streams = 0;
                    }
                }
                total = 1  // se o usuario existir no requests já significa ao menos 1 sessao
                for (let i = 0; i <  this.requestsRecordings.length; i++) {                
                    if (i <  this.requestsRecordings.length - 1 &&  this.requestsRecordings[i].requesterMail ==  this.requestsRecordings[i + 1].requesterMail) {                    
                        total++;  // vai somando as sessoes de gravacao daquele usuario ate o proximo usuario ser diferente
                    } else {
                        calcActions.push({ user:  this.requestsRecordings[i].requesterMail, requestsRecordings:total });
                        soma_req_recordings += total
                        total = 1;  // ultima entrada com aquele usuario --> salva o total e reseta o total pro proximo usuario
                    }
                }
                // metodo auxiliar para setar variaveis globais this.
                this.apresenta_total(somatoria_de_logins,soma_stream_times,soma_stream_sessoes,soma_req_recordings)
                /*calcActions.push({ 
                    user:  "Total",
                    loginsLogout: somatoria_de_logins ,
                    totalTime: soma_stream_times,
                    sessions: soma_stream_sessoes,
                    requestsRecordings: soma_req_recordings 
                    });  // precisa ser no ultimo elemento para aparecer no ultimo da tabela*/
                return this.OrganizeJson(calcActions)
                
                
            }
        },

    watch: {
        GetCurrentUser: {
            handler() {
                this.SelectedDate = []
                this.OperationReports = [] //Empty local warnings array so warnings from one operation don't mix with another.
                this.GetOperationReportsDatesAvailable() //Call method to check on dates available for the new operation selected.
            },
            deep: true //Watch for changes on the entire user object, not just its first level.
        }
    },
    methods: {
        // metodo para setar as variaveis do data: dentro do metodo calculateActions pq metodos
        //computed nao podem setar variaveis tipo this.
        //preciso das variaveis this. para apresentar no template
        apresenta_total(somatoria_de_logins_,soma_stream_times_,soma_stream_sessoes_,soma_req_recordings_){
            this.total_stream_times=soma_stream_times_;
            this.total_logins=somatoria_de_logins_;
            this.total_recordings=soma_req_recordings_;
            this.total_stream_sessoes=soma_stream_sessoes_;
        },
        //Find users of company 
        GetUserForCompany(){
            let operationReportDatesArray = []
            this.$store.dispatch('database/GetFromDatabase', {
                path: 'users/',
                returnSnapshotVal: false
            }).then(snapshot => {   
                snapshot.forEach(element => {
                    if(element.val().company=='vale'){
                        operationReportDatesArray.push(element.val().email)
                    }
                });
            })
            this.usersCompany=operationReportDatesArray
        },
        //Find requests recording
        GetRequestRecording(){
            this.requestsRecordings=[]
            this.SelectedDate.forEach(date => {
                date = new Date(date).toLocaleDateString('pt-BR').split('/').join('-')
                this.$store.dispatch('database/GetFromDatabase', {
                    path: 'mordor-request-recording/mrd-01-vale/date-index/'+date,
                    returnSnapshotVal: true
                    }).then(index =>{
                        if(index){
                            date = date.split('-')
                            this.$store.dispatch('database/GetFromDatabase', {
                            path: 'mordor-request-recording/mrd-01-vale/'+date[2]+'/'+date[1],
                            returnSnapshotVal: false
                            }).then(req=>{
                                req.forEach(element => {
                                    if(new Date(element.val().protocol).toLocaleDateString('pt-BR').split('/')[0]==date[0]){
                                        this.requestsRecordings.push(element.val())
                                    }
                                });
                            })
                        }
                    })
                });
            this.SelectedDate.forEach(date => {
                date = new Date(date).toLocaleDateString('pt-BR').split('/').join('-')
                this.$store.dispatch('database/GetFromDatabase', {
                    // path: 'mordor-request-recording/mrd-02-vale/date-index/'+date,
                    path: 'mordor-request-recording/mrd-03-vale/date-index/'+date,
                    returnSnapshotVal: true
                    }).then(index =>{
                        if(index){
                            date = date.split('-')
                            this.$store.dispatch('database/GetFromDatabase', {
                            // path: 'mordor-request-recording/mrd-02-vale/'+date[2]+'/'+date[1],
                            path: 'mordor-request-recording/mrd-03-vale/'+date[2]+'/'+date[1],
                            returnSnapshotVal: false
                            }).then(req=>{
                                req.forEach(element => {
                                    if(new Date(element.val().protocol).toLocaleDateString('pt-BR').split('/')[0]==date[0]){
                                        this.requestsRecordings.push(element.val())
                                    }
                                });
                            })
                        }
                    })
                });
        },
        //Find quota streaming for user
        GetStreamingUsed(){
            this.streamingUsed=[]
            this.counterStreaming=[]
             this.SelectedDate.forEach(date => {
                date = new Date(date).toLocaleDateString('pt-BR').split('/')
                this.$store.dispatch('database/GetFromDatabase', {
                path: 'mordor-history/mordor-vale-1/streamingUsed/'+date[2]+'/'+date[1],
                returnSnapshotVal: false
                }).then(stream=>{
                    stream.forEach(element => {
                        if(this.usersCompany.includes(element.val().email) && new Date(element.val().startTime).toLocaleDateString('pt-BR').split('/')[0]==date[0]){
                            console.log(element)
                            this.streamingUsed.push(element.val())
                            this.counterStreaming.push(element.val())
                        }
                    });
                })
            })
            this.SelectedDate.forEach(date => {
                date = new Date(date).toLocaleDateString('pt-BR').split('/')
                this.$store.dispatch('database/GetFromDatabase', {
                path: 'mordor-history/mordor-vale-2/streamingUsed/'+date[2]+'/'+date[1],
                returnSnapshotVal: false
                }).then(stream=>{
                    stream.forEach(element => {
                        if( (this.usersCompany.includes(element.val().email) && new Date(element.val().startTime).toLocaleDateString('pt-BR').split('/')[0]==date[0])){
                            this.streamingUsed.push(element.val())
                            this.counterStreaming.push(element.val())
                        }
                    });                    
                })
            })
            
        },
        //Find logs off login e logout in harpia
        GetLogs(){
               this.OperationReports = []
               this.countersReport = []
            this.SelectedDate.sort((dateOne, dateTwo) => { return dateOne - dateTwo })
            this.SelectedDate.forEach(date => {
                let modifiedDate = date.toLocaleDateString('pt-br').split('/').join('-')
                this.$store.dispatch('database/GetFromDatabase', {
                    path: 'actions-log/'+ modifiedDate,
                    returnSnapshotVal: false
                }).then(snapshot => { 
                    snapshot.forEach(childSnapshot => {
                        if(this.usersCompany.includes(childSnapshot.val().LogObject.user)){
                            this.OperationReports.push(childSnapshot.val())
                            this.countersReport.push(childSnapshot.val().LogObject.user)
                        }
                    })
                })
            })

        },
        // GetRequestRecording, GetStreamingUsed and GetLogs make json objects.GetLogs.


        // Here, Join these objects into one.
        MergeJson(obj1, obj2) { 
            Object.keys(obj1).forEach((key) => {  // todas as chaves do objeto 1 (encontrado anteriormente)
            if (obj2[key] && typeof obj2[key] === "number") obj2[key] += obj1[key];  // se a chave nao for "user" ou
                                                                                    // objeto2 nao tem aquela key ainda
                                                                                    // chaves já existente no obj2 --> soma
            else obj2[key] = obj1[key];  // acrescenta uma nova chave para o objeto2 ou repassa o valor na chave "user"
            });
            return obj2  // retorna o obj2 com os objetos1 adicionados
        },
        //After the objects are put together, here the data is organized for display in the table.
        OrganizeJson(json){  // pega todos os parametros e usuarios e junta tudo daquele usuario
            let result = [], seenBefore = [];

            json.forEach((object) => {  // for para percorrer todos os user: atributo
            let foundHere = seenBefore.indexOf(object.user);
            if (foundHere !== -1) {  // achou um atributo novo para o usuario q ja havia sido encontrado antes
                result[foundHere] = this.MergeJson(result[foundHere], object);  // acrescenta o atributo 
            } else {  // primeira vez que encontrou o usuario
                result.push(object);
                seenBefore.push(object.user);
            }
        });
        return result  // retorna um a lista com o formato {user:lalala},{atributo1: val1},{atributo2: val2}...}
        },


        //Make a file .csv
        GenerateCsv(){
                    var csv = 'Usuario, Atividade, Região, Cidade, Ip, Data, Hora, Duração\n';
                    this.OperationReports.forEach(row => {
                        csv+=row.LogObject.user;
                        csv+=','+row.LogObject.action;                        
                        csv+=','+row.LogObject.region;
                        csv+=','+row.LogObject.city;
                        csv+=','+row.LogObject.ip;
                        csv+=','+new Date(row.LogObject.hour).toLocaleDateString('pt-BR');
                        csv+=','+new Date(row.LogObject.hour).toLocaleTimeString('pt-BR'); 
                        csv+=', N/A';
                        csv += '\n';                     
                    });
                    this.requestsRecordings.forEach(row => {
                        csv+=row.requesterMail;
                        csv+=', Solicitação de gravação';                        
                        csv+=', N/A';
                        csv+=', N/a';
                        csv+=', N/A';
                        csv+=','+new Date(row.created).toLocaleDateString('pt-BR');
                        csv+=','+new Date(row.created).toLocaleTimeString('pt-BR'); 
                        csv+=', N/A';
                        csv += '\n';                       
                    });
                    this.streamingUsed.forEach(row => {
                        csv+=row.email;
                        csv+=', Streaming';                        
                        csv+=', N/A';
                        csv+=', N/a';
                        csv+=', N/A';
                        csv+=','+new Date(row.startTime).toLocaleDateString('pt-BR');
                        csv+=','+new Date(row.startTime).toLocaleTimeString('pt-BR'); 
                        csv+=','+this.MilisecondsConverter(row.endTime - row.startTime);
                        csv += '\n';                       
                    });                    
                    var hiddenElement = document.createElement('a');
                    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = 'Dados Mordor.csv';
                    hiddenElement.click();
        },
        //convert miliseconds in mm:ss format
        MilisecondsConverter(miliseconds){
          let time=' '
          if (miliseconds<60000) {
            time=(miliseconds/1000).toFixed(1)+'s'
          }
          if (miliseconds>=60000 && miliseconds<3600000 ) {
            time=parseInt(miliseconds/60000)+'m '+((miliseconds%60000)/1000).toFixed(0)+'s'            
          }
          if( miliseconds>3600000){              
              time=parseInt(miliseconds/60000)+'m '+((miliseconds%60000)/1000).toFixed(0)+'s'     
          }
          return time
        },      
        //Search all the information of the selected dates and insert it in the Array Logs
        ConfirmSelectedDates() {
            this.GetRequestRecording()
            this.GetStreamingUsed()
            this.GetLogs()
            this.showTable=true
        },
        // Make errors messages.
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000,
            })
        },
    },
    created() {
        this.GetUserForCompany()
    },
}
</script>


