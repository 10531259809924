<template>
    <div class="columns is-multiline">
        <div class="column has-text-left">
            <b-field label-position="on-border" grouped group-multiline>
                <template slot="label">
                    <span class="has-text-primary is-family-sans-serif">Selecione a(s) data(s):</span>
                </template>
                <b-datepicker v-model="SelectedDate" :month-names="MonthNames" :day-names="DayNames" :selectable-dates="LogsDatesAvailables" icon="calendar-alt" trap-focus multiple></b-datepicker>
                <p class="control">
                    <b-button class="button is-primary" @click="ConfirmSelectedDates" expanded outlined>
                        <span class="is-family-sans-serif">Confirmar</span>
                    </b-button>
                </p>
                <p class="control">
                    <b-button class="button is-warning" @click="GeneratePDFReport" v-if="IsTableShowing" icon-right="file-pdf" expanded outlined>
                        <span class="is-family-sans-serif">Baixar relatório</span>
                    </b-button>
                </p>
            </b-field>
        </div>
        <div class="column is-full">
            <ReportTableStopReport :acessInactiveStopReports="Logs" v-if="IsTableShowing" />
        </div>
        <div class="column is-full"></div>
    </div>
</template>
<style lang="scss" scope></style>
<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ReportTableStopReport from '@/components/management/ReportTableStopReport.vue'

export default {
    name: 'ReportStopReport',
    components: {
        ReportTableStopReport,
    },
    data() {
        return {
            SelectedDate: null, //Bound to datepicker component to hold user selected date to see logs.
            MonthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            DayNames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            IsTableShowing: false, //Used to whether shor or not table with logs.
            LogsDatesAvailables: [],
            Logs: [],
        }
    },
    watch: {
        Logs: {
            handler(logs) {
                if (logs) this.IsTableShowing = true
            },
        },
    },
    methods: {
        //Check the dates with available informations.
        SetLogsDatesAvailables() {
            this.$store.dispatch('database/GetFromDatabase', {
                path: 'stop-reports/inactive/' + this.GetCurrentUser.activeFlag.toLowerCase(),
                returnSnapshotVal: false
            }).then(dates => {
                Object.keys(dates.val()).forEach(date => {
                    let modifiedDate = date.split('-')
                    this.LogsDatesAvailables.push(new Date(modifiedDate[1] + '/' + modifiedDate[0] + '/' + modifiedDate[2]))
                })
            })
        },
        //Search all the information of the selected dates and insert it in the Array Logs
        ConfirmSelectedDates() {
            this.Logs = []
            this.SelectedDate.sort((dateOne, dateTwo) => { return dateOne - dateTwo })
            this.SelectedDate.forEach(date => {
                let modifiedDate = date.toLocaleDateString('pt-br').split('/').join('-')
                this.$store.dispatch('database/GetFromDatabase', {
                        path: 'operation-notes/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/' + modifiedDate,
                        returnSnapshotVal: false
                    }).then(snpashot => {
                        snpashot.forEach((childSnapshot) => { this.Logs.push(childSnapshot.val()) })
                    })
            })
        },
        //Used to convert the log table data which is shaped for a table component from Buefy to a structure used in jsPDF-AutoTable.
        ConvertLogTableToJsPDF(logs) {
            let rows = [] //Create empty array to house newly created rows.
            logs.forEach((cell) => {
                //For each object in SelectedDateLogs.
                let temporaryRows = [
                    //Fill temporary rows with data from inside the object on this iteration.
                    cell.flag.name,
                    cell.description,
                    cell.detail,
                    new Date(cell.timestamp).toLocaleDateString('pt-br'), //formats the date to the default dd/mm/yyyy
                    new Date(cell.timestamp).toLocaleTimeString('pt-br'),
                    new Date(cell.end).toLocaleDateString('pt-br'), //formats the date to the default dd/mm/yyyy
                    new Date(cell.end).toLocaleTimeString('pt-br'),
                    this.CalculeElapsedCalculator(cell.timestamp, cell.end), //calculates the period between dates
                ]
                rows.push(temporaryRows) //Push this temporary row to definitive rows array.
            })
            return rows //Return properly structured rows.
        },
        //Used to generate a PDF report using information created in the above methods about HARPIA usage.
        GeneratePDFReport() {
            try {
                const doc = new jsPDF(); //Instantiate new JsPDF document.
                const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth(); //Save PDF page size to variable so it is easier to center things.
                //variables for table with summary on the cover
                let ReportData = {
                    longestStop: 0,
                    longestStopDate: null,
                    totalStopTime: 0,
                    countOperationOut: 0,
                    totalOperationOutTime: 0,
                    countMaintenanceStop: 0,
                    totalMaintenanceStopTime: 0,
                    countClimateStop: 0,
                    totalClimateStopTime: 0,
                }
                this.Logs.forEach((log) => {
                    if (log.description == 'Parada por meteorologia') {
                        ReportData.countClimateStop += 1
                        ReportData.totalClimateStopTime = ReportData.totalClimateStopTime + (log.end - log.timestamp)
                    } else if (log.description == 'Parada para manutenção') {
                        ReportData.countMaintenanceStop += 1
                        ReportData.totalMaintenanceStopTime = ReportData.totalMaintenanceStopTime + (log.end - log.timestamp)
                    } else if (log.description == 'Fora de operação') {
                        ReportData.countOperationOut += 1
                        ReportData.totalOperationOutTime = ReportData.totalOperationOutTime + (log.end - log.timestamp)
                    }
                    // if to detect the longest stop.
                    if (log.end - log.timestamp > ReportData.longestStop) {
                        ReportData.longestStop = log.end - log.timestamp
                        ReportData.longestStopDate = log
                    }
                    //Calculates the total stop time
                    ReportData.totalStopTime = ReportData.totalStopTime + (log.end - log.timestamp)

                })
                //Subtitle of columns of cover
                const coverTableColumns = [
                    'Operação ',
                    'Total',
                    'Tempo Total',
                    'Total',
                    'Tempo Total',
                    'Total',
                    'Tempo Total',
                    'Total',
                    'Tempo Total',
                ]
                //data for table of summary of cover
                const coverTableRows = [
                    this.GetCurrentUser.activeFlag.toLowerCase(),
                    this.Logs.length,
                    this.CalculeElapsedCalculator(0, ReportData.totalStopTime),
                    ReportData.countOperationOut,
                    this.CalculeElapsedCalculator(0, ReportData.totalOperationOutTime),
                    ReportData.countMaintenanceStop,
                    this.CalculeElapsedCalculator(0, ReportData.totalMaintenanceStopTime),
                    ReportData.countClimateStop,
                    this.CalculeElapsedCalculator(0, ReportData.totalClimateStopTime),
                ]
                //Table of cover
                doc.autoTable({
                    //function for style in first row of table
                    didParseCell(doc) {
                        if (doc.row.index === 0 && doc.row.section === 'body') {
                            doc.cell.styles.fillColor = [15, 76, 129]; //background blue harpia
                            doc.cell.styles.textColor = [255, 255, 255] //text color white
                        }
                    },
                    startY: 200,
                    head: [
                        [{
                                content: ' ',
                                styles: {
                                    halign: 'center',
                                    fillColor: [22, 160, 133],
                                },
                            },
                            {
                                content: 'Total de paradas',
                                colSpan: 2,
                                styles: {
                                    halign: 'center',
                                    fillColor: [22, 160, 133],
                                    textColor: [255, 255, 255],
                                    fontSize: 9,
                                },
                            },
                            {
                                content: 'Fora de operação',
                                colSpan: 2,
                                styles: {
                                    halign: 'center',
                                    fillColor: [22, 160, 133],
                                    textColor: [255, 255, 255],
                                    fontSize: 9,
                                },
                            },
                            {
                                content: 'Paradas por manutenção',
                                colSpan: 2,
                                styles: {
                                    halign: 'center',
                                    fillColor: [22, 160, 133],
                                    textColor: [255, 255, 255],
                                    fontSize: 9,
                                },
                            },
                            {
                                content: 'Fora por meteorologia',
                                colSpan: 2,
                                styles: {
                                    halign: 'center1',
                                    fillColor: [22, 160, 133],
                                    textColor: [255, 255, 255],
                                    fontSize: 8.5,
                                },
                            },
                        ],
                    ],
                    body: [coverTableColumns, coverTableRows],
                    tableLineColor: [0, 0, 0], //table external border color
                    tableLineWidth: 0.1, //table external border
                    //border of cells and align text
                    styles: {
                        lineColor: [0, 0, 0],
                        lineWidth: 0.1,
                        textColor: [0, 0, 0],
                        halign: 'center',
                    },
                    theme: 'grid',
                })
                doc.setFontStyle('bold') //Set font weight of next lines.
                doc.setFontSize(30)
                doc.text('Relatório de paradas', pageWidth / 2, 40, 'center');
                doc.setFontSize(20)
                doc.text('Operação: ' + this.GetCurrentUser.activeFlag.toUpperCase(), pageWidth / 2, 50, 'center')
                doc.setFontSize(20) //Set font size of next lines.
                doc.text(
                    '(' + new Date(this.Logs[0].timestamp).toLocaleDateString('pt-br') + ' - ' +
                    new Date(this.Logs[this.Logs.length - 1].timestamp).toLocaleDateString('pt-br') + ')',
                    pageWidth / 2, 60, 'center'
                )
                doc.text(
                    'Período: ' + new Date(this.Logs[0].timestamp).toLocaleString('pt-br') +
                    ' a ' + new Date(this.Logs[this.Logs.length - 1].timestamp).toLocaleString('pt-br'),
                    pageWidth / 2, 160, 'center'
                )
                doc.text('Total de Paradas: ' + this.Logs.length, pageWidth / 2, 170, 'center')
                doc.setFontSize(20)
                doc.text(
                    'Parada mais longa: ' + Math.floor(ReportData.longestStop / 1000 / 60 / 60) + 'h' +
                    Math.floor((ReportData.longestStop / 1000 / 60) % 60) + 'min' +
                    ' em ' + new Date(ReportData.longestStopDate.timestamp).toLocaleDateString('pt-br'),
                    pageWidth / 2, 180, 'center'
                )
                doc.setFontSize(20)
                doc.text(
                    'Tempo total de paradas: ' + Math.floor(ReportData.totalStopTime / 1000 / 60 / 60) + 'h' +
                    Math.floor((ReportData.totalStopTime / 1000 / 60) % 60) + 'min',
                    pageWidth / 2, 190, 'center'
                )
                doc.addPage('a4', 'p') //Add new A4 page.
                const logTableColumns = [
                    'Operação',
                    'Motivo',
                    'Observação',
                    'Data Inicio',
                    'Inicio',
                    'Data Fim',
                    'Fim',
                    'Duração',
                ] //Initiates PDF table headers.
                const logTableRows = this.ConvertLogTableToJsPDF(this.Logs) //Initiates PDF table rows.
                doc.setFontSize(20) //Set font size of next lines.
                doc.text('Dados das paradas: ', pageWidth / 2, 10, 'center')
                doc.setFontSize(12) //Set font size of next lines.
                doc.setFontStyle('bold') //Set font weight of next lines.
                doc.autoTable(logTableColumns, logTableRows, {
                    startY: 20,
                    tableWidth: 'auto',
                    theme: 'grid',
                    margin: { left: 10 },
                    styles: {
                        overflow: 'linebreak',
                        cellWidth: 'wrap',
                        columnWidth: 'wrap',
                    },
                    columnStyles: {
                        1: {
                            columnWidth: 'auto',
                            cellWidth: 'auto',
                        },
                        2: {
                            columnWidth: 'auto',
                            cellWidth: 'auto',
                        },
                        0: {
                            columnWidth: 'auto',
                            cellWidth: 'auto',
                        },
                    },
                }) //Transforms given columns and rows array in a table.
                doc.save('Relatório de paradas ' + new Date().toLocaleString('pt-br') + '.pdf') //Saves created table to PDF.
            } catch (error) {
                this.LaunchToast('Não há dados suficientes para gerar relatório!' + error, 'is-danger')
            }
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000,
            })
        },
        CalculeElapsedCalculator(timeStart, timeEnd) {
            let seconds = Math.floor((timeEnd - timeStart) / 1000) //in s
            let minutes = Math.floor(seconds / 60) //in minutes
            let hours
            if (minutes >= 60) {
                hours = Math.floor(minutes / 60)
                minutes = Math.floor(minutes % 60)
                return hours + 'h' + minutes + 'min'
            } else {
                return minutes + 'min'
            }
        },
    },
    created() {
        this.SetLogsDatesAvailables()
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
    },
}
</script>