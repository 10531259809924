<template>
    <section>
        <p class="title">{{ $t('about.title') }}</p>
        <b-tabs size="is-small" type="is-toggle" position="is-centered">
            <b-tab-item :label="$t('about.menubar.overview')">
                 <div class="has-text-left">
                    <p>
                        {{ $t('about.overview') }}
                    </p>
                </div>
            </b-tab-item>
            <!-- <b-tab-item label="Inserir usuário"> -->
            <b-tab-item :label="$t('about.menubar.insertUser')">
                <div class="container">
                    <div class="has-text-left">
                        <p>
                            <!-- Na opção Inserir Usuário, no contexto de Controle de Usuários, o objetivo é cadastrar os usuários e suas permissões. -->
                            {{ $t('about.insertUser.text') }}
                        </p>
                        <br>
                        <ul>
                            <li><b>{{ $t('about.insertUser.name') }}: </b>{{ $t('about.insertUser.nameText') }}</li>
                            <li><b>Email: </b>{{ $t('about.insertUser.emailText') }}</li>
                            <li><b>{{ $t('about.insertUser.password') }}: </b>{{ $t('about.insertUser.passwordText') }}</li>
                            <li><b>{{ $t('about.insertUser.operation') }}: </b>{{ $t('about.insertUser.operationText') }}</li>
                            <br>
                            <li><b>{{ $t('about.role.role') }}: </b>{{ $t('about.role.textInsertUser') }}</li>
                            <p>
                                <ul>
                                    <li><b>Supervisor: </b> {{ $t('about.role.supervisorText') }}</li>
                                    <li><b>{{ $t('about.role.operator') }}: </b> {{ $t('about.role.operatorText') }}</li>
                                    <li><b>{{ $t('about.role.viewer') }}: </b>{{ $t('about.role.viewerText') }}</li>
                                </ul>
                            </p>
                            <br>
                            <li><b>{{ $t('about.permissions.permissions') }}:</b> {{ $t('about.permissions.textInsertUser') }}
                                <p>
                                    <ul>
                                        <li><b>{{ $t('about.permissions.streaming') }}: </b>{{ $t('about.permissions.streamingText') }}</li>
                                        <li><b>{{ $t('about.permissions.georef') }}: </b>{{ $t('about.permissions.georefText') }}</li>
                                        <li><b>{{ $t('about.permissions.cameraControl') }}: </b>{{ $t('about.permissions.cameraControlText') }}</li>
                                        <li><b>{{ $t('about.permissions.viewAlerts') }}: </b>{{ $t('about.permissions.viewAlertsText') }} </li>
                                        <li><b>{{ $t('about.permissions.deleteAlerts') }}: </b>{{ $t('about.permissions.deleteAlertsText') }}</li>
                                        <li><b>{{ $t('about.permissions.sauron') }}: </b> {{ $t('about.permissions.sauronText') }}</li>
                                        <li><b>{{ $t('about.permissions.mordor') }}: </b>{{ $t('about.permissions.mordorText') }}</li>
                                    </ul>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-tab-item>
            <!-- <b-tab-item label="Alterar usuário"> -->
            <b-tab-item :label="$t('about.menubar.changeUser')">
                <div class="container">
                    <div class="has-text-left">
                        <p>
                            {{ $t('about.changeUser.text') }}
                        </p>
                        <br>
                        <ul>
                            <li><b>{{ $t('about.changeUser.flag') }}: </b> {{ $t('about.changeUser.flagText') }}</li>
                            <br>
                            <li><b>{{ $t('about.permissions.permissions') }}:</b> {{ $t('about.permissions.textChangeUser') }}
                                <p>
                                    <ul>
                                        <li><b>{{ $t('about.permissions.streaming') }}: </b>{{ $t('about.permissions.streamingText') }}</li>
                                        <li><b>{{ $t('about.permissions.georef') }}: </b>{{ $t('about.permissions.georefText') }}</li>
                                        <li><b>{{ $t('about.permissions.cameraControl') }}: </b>{{ $t('about.permissions.cameraControlText') }}</li>
                                        <li><b>{{ $t('about.permissions.viewAlerts') }}: </b>{{ $t('about.permissions.viewAlertsText') }} </li>
                                        <li><b>{{ $t('about.permissions.deleteAlerts') }}: </b>{{ $t('about.permissions.deleteAlertsText') }}</li>
                                        <li><b>{{ $t('about.permissions.sauron') }}: </b> {{ $t('about.permissions.sauronText') }}</li>
                                        <li><b>{{ $t('about.permissions.mordor') }}: </b>{{ $t('about.permissions.mordorText') }}</li>
                                    </ul>
                                </p>
                            </li>
                            <br>
                            <li><b>{{ $t('about.role.role') }}: </b>{{ $t('about.role.textChangeUser') }}</li>
                            <p>
                                <ul>
                                    <li><b>Supervisor: </b> {{ $t('about.role.supervisorText') }}</li>
                                    <li><b>{{ $t('about.role.operator') }}: </b> {{ $t('about.role.operatorText') }}</li>
                                    <li><b>{{ $t('about.role.viewer') }}: </b>{{ $t('about.role.viewerText') }}</li>
                                </ul>
                            </p>
                        </ul>
                    </div>
                </div>
            </b-tab-item>
            <!-- <b-tab-item label="RERO/RHRO"> -->
            <b-tab-item :label="$t('about.menubar.eorrHorr')">
                <div class="container">
                    <div class="has-text-left">
                        <p>
                            {{ $t('about.eorrHorr.text') }}
                        </p>
                        <br>
                        <ul>
                            <li><b>{{ $t('about.eorrHorr.type') }}: </b>{{ $t('about.eorrHorr.typeText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.status') }}: </b>{{ $t('about.eorrHorr.statusText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.author') }}: </b>{{ $t('about.eorrHorr.authorText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.mooringReason') }}: </b>{{ $t('about.eorrHorr.mooringReasonText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.problemOrigin') }}: </b>{{ $t('about.eorrHorr.problemOriginText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.monitoringRoutine') }}: </b>{{ $t('about.eorrHorr.monitoringRoutineText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.liveBroadcast') }}:  </b>{{ $t('about.eorrHorr.liveBroadcastText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.liveBroadcast') }}:  </b>{{ $t('about.eorrHorr.liveBroadcastText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.flightTime') }}:  </b>{{ $t('about.eorrHorr.flightTimeText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.plc') }}:  </b>{{ $t('about.eorrHorr.plcText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.cameraData') }}:  </b>{{ $t('about.eorrHorr.cameraDataText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.shelfData') }}:  </b>{{ $t('about.eorrHorr.shelfDataText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.airshipData') }}:  </b>{{ $t('about.eorrHorr.airshipDataText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.report') }}:  </b>{{ $t('about.eorrHorr.reportText') }}</li>
                            <li><b>{{ $t('about.eorrHorr.meteorologicalData') }}:  </b>{{ $t('about.eorrHorr.meteorologicalDataText') }}</li>
                        </ul>
                    </div>
                </div>
            </b-tab-item>
            <!-- <b-tab-item label="Inserir Transmissão"> -->
            <b-tab-item :label="$t('about.menubar.insertLivestream')">
                <div class="container">
                    <div class="has-text-left">
                        <p>
                            {{ $t('about.insertLivestream.text') }}
                        </p>
                        <br>
                        <ul>
                            <li><b>{{ $t('about.insertLivestream.videoTransmissionURL') }}:  </b>{{ $t('about.insertLivestream.videoTransmissionURLText') }}</li>
                            <li><b>{{ $t('about.insertLivestream.description') }}: </b>{{ $t('about.insertLivestream.descriptionText') }}</li>
                            <li><b>{{ $t('about.insertLivestream.operation') }}: </b>{{ $t('about.insertLivestream.operationText') }}</li>
                        </ul>
                    </div>
                </div>
            </b-tab-item>
            <!-- <b-tab-item label="Inserir Alerta"> -->
            <b-tab-item :label="$t('about.menubar.insertWarning')">
                <div class="container">
                    <div class="has-text-left">
                        <p>
                            {{ $t('about.insertWarning.text') }}
                        </p>
                        <br>
                        <ul>
                            <li><b>{{ $t('about.insertWarning.latitude') }}: </b>{{ $t('about.insertWarning.latitudeText') }}</li>
                            <li><b>{{ $t('about.insertWarning.longitude') }}: </b>{{ $t('about.insertWarning.longitudeText') }}</li>
                            <li><b>{{ $t('about.insertWarning.operation') }}: </b>{{ $t('about.insertWarning.operationText') }}</li>
                            <li><b>{{ $t('about.insertWarning.occurrence') }}: </b>{{ $t('about.insertWarning.occurrenceText') }}</li>
                            <li><b>{{ $t('about.insertWarning.chooseImage') }}: </b>{{ $t('about.insertWarning.chooseImageText') }}</li>
                        </ul>
                    </div>
                </div>
            </b-tab-item>
            <!-- <b-tab-item label="Acessos de usuários"> -->
            <b-tab-item :label="$t('about.menubar.usersAccesses')">
                <div class="container">
                    <div class="has-text-left">
                        <p>
                            {{ $t('about.usersAccesses.text') }}
                        </p>
                        <br>
                        <ul>
                            <li><b>{{ $t('about.usersAccesses.user') }}: </b>{{ $t('about.usersAccesses.userText') }}</li>
                            <li><b>{{ $t('about.usersAccesses.region') }}: </b>{{ $t('about.usersAccesses.regionText') }}</li>
                            <li><b>{{ $t('about.usersAccesses.city') }}: </b>{{ $t('about.usersAccesses.cityText') }}</li>
                            <li><b>{{ $t('about.usersAccesses.ip') }}: </b>{{ $t('about.usersAccesses.ipText') }}</li>
                            <li><b>{{ $t('about.usersAccesses.action') }}: </b>{{ $t('about.usersAccesses.actionText') }}</li>
                            <li><b>{{ $t('about.usersAccesses.date') }}: </b>{{ $t('about.usersAccesses.dateText') }}</li>
                            <li><b>{{ $t('about.usersAccesses.time') }}: </b>{{ $t('about.usersAccesses.timeText') }}</li>
                        </ul>
                    </div>
                </div>
            </b-tab-item>
            <!-- <b-tab-item label="Disponibilidade de voo"> -->
            <b-tab-item :label="$t('about.menubar.flightAvailability')">
                <div class="container">
                    <div class="has-text-left">
                        <p>
                           {{ $t('about.flightAvailability.text1') }}
                        </p>
                        <p>
                            {{ $t('about.flightAvailability.text2') }}
                        </p>
                        <br>
                        <ul>
                            <li><b>{{ $t('about.flightAvailability.type') }}: </b>{{ $t('about.flightAvailability.typeText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.date') }}: </b>{{ $t('about.flightAvailability.dateText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.time') }}: </b>{{ $t('about.flightAvailability.timeText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.notes') }}: </b>{{ $t('about.flightAvailability.notesText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.altitude') }}: </b>{{ $t('about.flightAvailability.altitudeText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.meteorologicalData') }}: </b>{{ $t('about.flightAvailability.meteorologicalDataText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.status') }}: </b>{{ $t('about.flightAvailability.statusText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.stopCause') }}: </b>{{ $t('about.flightAvailability.stopCauseText') }}</li>
                            <li><b>{{ $t('about.flightAvailability.author') }}: </b>{{ $t('about.flightAvailability.authorText') }}</li>
                        </ul>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
    </section>
</template>
<style lang="scss" scoped>
p {
    text-indent: 8vh;
}
</style>
<script>
export default {
    name: 'AboutGuide',

    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser'];
        }
    },

    created() {
        //         this.$store.dispatch('events/generateEvents',{
        //         'page': 'Guia para inserir usuários',
        //         'user':this.GetCurrentUser.name, 
        //         'Date':new Date(Date.now()).toLocaleDateString(),
        //         'Time':new Date(Date.now()).toLocaleTimeString(),
        //         'ActiveFlag':this.GetCurrentUser.activeFlag
        //         })        
    },

}
</script>