<template>
    <div>
        <TheNavbar />
        <section class="section is-less-padding-top">
            <div class="columns">
                <div class="column has-bottom-gap is-narrow has-background-white has-circle-border has-box-shadow">
                    <SideMenu @clicked="ReceiveOptionFromManagementMenu" />
                </div>
                <div class="column has-left-gap has-background-white has-circle-border has-box-shadow">
                    <component :is="CurrentOption"></component>
                </div>
            </div>
        </section>
    </div>
</template>
<style lang="scss" scoped>
.section.is-less-padding-top {
    padding-top: 2.5vh;
}

@media (min-width: 481px) and (max-width: 767px) {
    .has-left-gap {
        margin-left: 0vh !important;
    }

    .has-bottom-gap {
        margin-bottom: 2vh;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .has-left-gap {
        margin-left: 0vh !important;
    }

    .has-bottom-gap {
        margin-bottom: 2vh;
    }
}

.has-left-gap {
    margin-left: 2vh;
}

.has-circle-border {
    border-radius: 8px;
}

.has-box-shadow {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
</style>
<script>
import TheNavbar from '@/components/global/TheNavbar.vue'
import SideMenu from '@/components/management/SideMenu.vue'
import AboutVersion from '@/components/management/AboutVersion.vue'
import AboutGuide from '@/components/management/AboutGuide.vue'
import LivestreamInsert from '@/components/management/LivestreamInsert.vue'
import ReportStopReport from '@/components/management/ReportStopReport.vue'
import ReportUserPresence from '@/components/management/ReportUserPresence.vue'
import UserInsert from '@/components/management/UserInsert.vue'
import UserModify from '@/components/management/UserModify.vue'
import HirerInsert from '@/components/management/HirerInsert.vue'
import WarningInsert from '@/components/management/WarningInsert.vue'
import OperationReportInsert from '@/components/management/OperationReportInsert.vue'
import ReportOperationReport from '@/components/management/ReportOperationReport.vue'
import TheDashboard from '@/components/management/TheDashboard.vue'
// import RequestRecording from '@/components/management/RequestRecording.vue'
// import MordorDashboard from '@/components/management/MordorDashboard.vue'
import MordorOperationReport from '@/components/mordor/MordorOperationReport.vue'
// import TheGrid from '@/components/mordorTestes/TheGrid.vue'
import MordorAvailability from '@/components/management/MordorAvailability.vue'
import ReportRonda from '../components/management/ReportRonda.vue'
export default {
    name: 'Management',
    components: {
        TheNavbar,
        SideMenu,
        UserInsert,
        UserModify,
        HirerInsert,
        LivestreamInsert,
        ReportUserPresence,
        ReportStopReport,
        AboutVersion,
        AboutGuide,
        WarningInsert,
        OperationReportInsert,
        ReportOperationReport,
        TheDashboard,
        // RequestRecording,
        // MordorDashboard,
        MordorOperationReport,
        // TheGrid,
        MordorAvailability,
        ReportRonda
    },
    data() {
        return {
            CurrentOption: 'TheDashboard',
        }
    },
    methods: {
        ReceiveOptionFromManagementMenu(value) {
            this.CurrentOption = value
        }
    }
}
</script>