<template>
    <div>
        <b-field label-position="on-border">
            <template slot="label">
                <span class="has-text-primary is-family-sans-serif">Whatsapp Report</span>
            </template>
            <textarea class="is-custom-height" ref="generatedReport" v-model="WhatsappReport"></textarea>
        </b-field>
        <div class="buttons">
            <b-button @click="CopyGeneratedReportToClipboard()">Copy</b-button>
            <b-button type="is-success" tag="a" :href="'whatsapp://send?text=' + GenerateWhatsappReport('%0a')" target="_blank">Share on WhatsApp</b-button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.is-custom-height {
    width: 100%;
    height: 25vh;
    font-size: larger;
}
</style>
<script>
export default {
    name: 'OperationReportGenerator',
    props: {
        OperationReportObject: Object
    },
    data() {
        return {
            WhatsappReport: ''
        }
    },
    methods: {
        CopyGeneratedReportToClipboard() {
            this.$refs.generatedReport.select()
            document.execCommand('copy')
        },
   GenerateWhatsappReport(encoding) {
            let flightStatus = ''
            let monitoringStatus = ''
            let livestreamStatus = ''
            let stoppedFlightCondition = ''
            let maintenanceType = ''
            let problemMotive = ''
            let weatherDataReport = ''

            if (this.OperationReportObject.weatherDataObject) {
                weatherDataReport =
                    encoding + '*Aerostat altitude (GPS):* ' + this.OperationReportObject.weatherDataObject.geocam.aerostatAltitude + ' meters' +
                    encoding + '*Cable slop (GPS):* ' + this.OperationReportObject.weatherDataObject.geocam.cableInclination + ' degrees' +
                    encoding + '*Wind speed (Windy):* ' + this.OperationReportObject.weatherDataObject.windy.windSpeed + ' KT' +
                    encoding + '*Gust speed (Windy):* ' + this.OperationReportObject.weatherDataObject.windy.gustSpeed + ' KT' +
                    encoding + '*Wind speed (Local station):* ' + this.OperationReportObject.weatherDataObject.localStation.windSpeed + ' KT' +
                    encoding + '*Atmospheric pressure (Local station):* ' + this.OperationReportObject.weatherDataObject.localStation.atmosphericPressure + ' HPA' +
                    encoding + '*Cloud reflectivity (Cemaden):* ' + this.OperationReportObject.weatherDataObject.cemaden.clouds + ' DBZ' +
                    encoding + '*Electric field (Boltek):* ' + this.OperationReportObject.weatherDataObject.lightningDetector.lightning +
                    encoding + '*Electric field distance (Boltek):* ' + this.OperationReportObject.weatherDataObject.lightningDetector.distance + ' KM' +
                    encoding + '*Anemometer:* ' + this.OperationReportObject.weatherDataObject.local.windSpeed + ' KT'
            }
            switch (this.OperationReportObject.aerostatInfo.status) {
                case 'flying-no-problem':
                    flightStatus = 'Aerostat flying without problems'
                    break
                case 'flying-with-problem':
                    flightStatus = 'Aerostat flying with problems'
                    break
                case 'anchored':
                    flightStatus = 'Aerostat Docked'
                    break
                case 'takeoff':
                    flightStatus = 'Start of Flight'
                    break
                case 'partial':
                    flightStatus = 'Altitude Change'
                    break
                case 'landing':
                    flightStatus = 'Docking'
                    break
            }
            if (this.OperationReportObject.aerostatInfo.status === 'flying-no-problem' || this.OperationReportObject.aerostatInfo.status === 'flying-with-problem' || this.OperationReportObject.aerostatInfo.status === 'partial' || this.OperationReportObject.aerostatInfo.status === 'takeoff') {
                switch (this.OperationReportObject.monitoringStatus) {
                    case 'tour':
                        monitoringStatus = 'Automatic Patrol'
                        break
                    case 'manual':
                        monitoringStatus = 'Manual Monitoring'
                        break
                }
                switch (this.OperationReportObject.livestreamStatus) {
                    case true:
                        livestreamStatus = '✔️'
                        break
                    case false:
                        livestreamStatus = '❌'
                        break
                }
                if (this.OperationReportObject.aerostatInfo.status === 'flying-with-problem') {
                    switch (this.OperationReportObject.problemMotive) {
                        case 'external':
                            problemMotive = 'External'
                            break
                        case 'internal':
                            problemMotive = 'Internal'
                            break
                    }
                    return encoding + '' + this.OperationReportObject.type.toUpperCase() + '' + new Date(this.OperationReportObject.timestamp).toLocaleString() + '' +
                        encoding + '*Aerostat Status:* ' + flightStatus +
                        encoding + '*Source of problem:* ' + problemMotive +
                        encoding + '*Cable released:* ' + this.OperationReportObject.aerostatInfo.altitude + ' meters' +
                        encoding + '*Hours of flying:* ' + this.OperationReportObject.aerostatInfo.flightHours + ' hours and ' +
                        this.OperationReportObject.aerostatInfo.flightMinutes + ' minutes' +
                        encoding + '*Monitoring routine:* ' + monitoringStatus +
                        encoding + '*Livestream:* ' + livestreamStatus +
                        encoding + '*Transmission rate of PLC (Mb):* ' + '⬆️ ' + this.OperationReportObject.plcTransmission.upload + ' and ' + '⬇️ *' + this.OperationReportObject.plcTransmission.download +

                        encoding + '*CAMERA Model:* ' + this.OperationReportObject.payloadInfo.cameraModel +
                        encoding + '*CAMERA IR Cooler:* ' + this.OperationReportObject.payloadInfo.irCoolerStatus +
                        encoding + '*GONDOLA S/N:* ' + this.OperationReportObject.payloadInfo.gondola.serialNumber +
                        encoding + '*GONDOLA P/N:* ' + this.OperationReportObject.payloadInfo.gondola.partNumber +
                        encoding + '*External Envelope:* ' + this.OperationReportObject.aerostatInfo.nylon.number +
                        ' (Assembled since ' + new Date(this.OperationReportObject.aerostatInfo.nylon.timestamp).toLocaleDateString() + ')' +
                        encoding + '*TPU:* '+this.OperationReportObject.aerostatInfo.tpu.number +
                        ' (Assembled since ' + new Date(this.OperationReportObject.aerostatInfo.tpu.timestamp).toLocaleDateString() + ')' +

                        encoding + '*Notes:* ' + this.OperationReportObject.notes +
                        encoding + '*Operator:* ' + this.OperationReportObject.author +
                        encoding + '--' +
                        weatherDataReport +
                        encoding 	

                } else {
                    return encoding + '' + this.OperationReportObject.type.toUpperCase() + ' ' + new Date(this.OperationReportObject.timestamp).toLocaleString() + '' +
                        encoding + '*Aerostat Status:* ' + flightStatus +
                        encoding + '*Cable released:* ' + this.OperationReportObject.aerostatInfo.altitude + ' meters' +
                        encoding + '*Hours of flying:* ' + this.OperationReportObject.aerostatInfo.flightHours + ' hours and ' +
                        this.OperationReportObject.aerostatInfo.flightMinutes + ' minutes' +
                        encoding + '*Monitoring routine:* ' + monitoringStatus +
                        encoding + '*Livestream:* ' + livestreamStatus +
                        encoding + '*Transmission rate of (Mb):* ' + '⬆️ ' + this.OperationReportObject.plcTransmission.upload + ' and ' + '⬇️ ' + this.OperationReportObject.plcTransmission.download +

                        encoding + '*CAMERA Model:* ' + this.OperationReportObject.payloadInfo.cameraModel +
                        encoding + '*CAMERA IR Cooler:* ' + this.OperationReportObject.payloadInfo.irCoolerStatus +
                        encoding + '*GONDOLA S/N:* ' + this.OperationReportObject.payloadInfo.gondola.serialNumber +
                        encoding + '*GONDOLA P/N:* ' + this.OperationReportObject.payloadInfo.gondola.partNumber +
                        encoding + '*External Envelope:* ' + this.OperationReportObject.aerostatInfo.nylon.number +
                        ' ( ' + new Date(this.OperationReportObject.aerostatInfo.nylon.timestamp).toLocaleDateString() + ')' +
                        encoding + '*TPU:* '+this.OperationReportObject.aerostatInfo.tpu.number +
                        ' ( ' + new Date(this.OperationReportObject.aerostatInfo.tpu.timestamp).toLocaleDateString() + ')' +

                        encoding + '*Notes:* ' + this.OperationReportObject.notes +
                        encoding + '*Operator:* ' + this.OperationReportObject.author +
                        encoding + '--' +
                        weatherDataReport +
                        encoding 	
                }
            } else {
                switch (this.OperationReportObject.aerostatInfo.stoppedFlightCondition) {
                    case 'meteorology':
                        stoppedFlightCondition = 'Meteorology'
                        break
                    case 'maintenance':
                        stoppedFlightCondition = 'Maintenance'
                        break
                    case 'pre-flight':
                        stoppedFlightCondition = 'Pre-flight procedure'
                        break
                    case 'training':
                        stoppedFlightCondition = 'Training'
                        break
                    case 'non-working-hours':
                        stoppedFlightCondition = 'Out of hours'
                        break
                    case 'misc-external':
                        stoppedFlightCondition = 'Others - External factors'
                        break
                    case 'misc-internal':
                        stoppedFlightCondition = 'Others - Internal factors'
                        break
                }
                switch (this.OperationReportObject.maintenanceInfo.type) {
                    case 'corrective':
                        maintenanceType = 'Corretive'
                        break
                    case 'preventive':
                        maintenanceType = 'Preventive'
                        break
                }
                if (this.OperationReportObject.aerostatInfo.stoppedFlightCondition === 'maintenance') {
                    let reportObject = new Date(this.OperationReportObject.timestamp).toLocaleString() + '' +
                        encoding + '*Aerostat Status:* ' + flightStatus +
                        encoding + '*Docking Reason:* ' + stoppedFlightCondition +
                        encoding + '*Maintenance Type:* ' + maintenanceType +
                        encoding + '*Equipment:* ' + this.OperationReportObject.maintenanceInfo.maintenanceEquipment.name +
                        encoding + '*Notes:* ' + this.OperationReportObject.notes +
                        encoding + '*Operator:* ' + this.OperationReportObject.author +
                        encoding + '--' +
                        weatherDataReport +
                        encoding 	
                        return this.OperationReportObject.type.toUpperCase()=="RERO"?"EORR" + ' ' + reportObject:"HORR" + ' ' + reportObject	
                } else {
                    return this.OperationReportObject.type.toUpperCase() + ' ' + new Date(this.OperationReportObject.timestamp).toLocaleString() + '' +
                        encoding + '*Aerostat Status:* ' + flightStatus +
                        encoding + '*Docking Reason:* ' + stoppedFlightCondition +
                        encoding + '*Notes:* ' + this.OperationReportObject.notes +
                        encoding + '*Operator:* ' + this.OperationReportObject.author +
                        encoding + '--' +
                        weatherDataReport +
                        encoding 	
                }
            }
        },
        CheckIfTextNotNull(text) {
            if (!text) return 'No information'
            else return text
        }
    },
    created() {
        this.WhatsappReport = this.GenerateWhatsappReport('\n')
    }
}
</script>