<template>
    <div>
        <div>
            <p class="has-text-weight-bold">{{ $t('management.dashboard.welcome') }} {{ GetCurrentUser.name.split(' ')[0] }}.</p>
            <p class="is-size-7">{{ TimeNow.toLocaleTimeString('pt-br') }} - {{ TimeNow.toLocaleDateString('pt-br') }}</p>
            <br>
        </div>
        <div class="columns is-multiline is-centered">
            <DashboardCard class="column is-4 box has-right-margin" v-for="flag in GetCurrentUserFilteredFlags" :key="flag.name" :Flag="flag"></DashboardCard>
            <div class="section column is-full has-text-left" v-if="GetCurrentUser.role === 'admin' || (GetCurrentUser.role === 'supervisor' && GetCurrentUser.activeFlag.toLowerCase()!='newmont')">
                <p class="has-text-centered has-text-weight-bold">{{ $t('management.dashboard.onlineUsers') }}</p>
                <hr>
                <b-table :data="GetOnlineUsers" :striped="IsStriped" :narrowed="IsNarrowed" :bordered="IsBordered" :per-page="PerPage" :current-page.sync="CurrentPage" :pagination-simple="IsPaginationSimple" :pagination-position="PaginationPosition" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">
                    <b-table-column field="name" :label="$t('management.dashboard.userTable.user')" width="40" v-slot="props">
                        <span v-if="props.row.name">
                            {{ props.row.name }}
                        </span>
                        <span v-else>
                            {{ props.row.user.split('@')[0] }}
                        </span>
                    </b-table-column>
                    <b-table-column field="region" :label="$t('management.dashboard.userTable.state')" width="40" v-slot="props">
                        {{ props.row.region }}
                    </b-table-column>
                    <b-table-column field="region" :label="$t('management.dashboard.userTable.company')" width="40" v-slot="props">
                        <span class="is-capitalized">{{ props.row.company }}</span>
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scope>
.has-right-margin {
    margin-right: 1vw;
}
</style>
<script>
import DashboardCard from "@/components/management/TheDashboardCard.vue"
export default {
    name: "Dashboard",
    components: {
        DashboardCard
    },
    data() {
        return {
            LogsDatesAvailables: [],
            TimeNow: new Date(),
            IsPaginated: true,
            IsPaginationSimple: false,
            IsStriped: false,
            IsBordered: false,
            IsNarrowed: false,
            PaginationPosition: 'bottom',
            CurrentPage: 1,
            PerPage: 6
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser'];
        },
        GetCurrentUserFilteredFlags() {
            let filteredFlags = this.$store.getters['user/GetCurrentUser'].flag.filter(flag => { return flag.type === 'aerostat' }) //Used to get only operations with a areostat.
            return filteredFlags
        },
        GetOnlineUsers() {
            return this.$store.getters['database/GetOnlineUsers']
        },
    },
    methods: {
        UpdateDashboardTimer() {
            this.TimeNow = new Date()
        },
    },
    created() {
        this.$store.dispatch('database/bindOnlineUsers')
        setInterval(this.UpdateDashboardTimer, 1000)
    },
    beforeDestroy() {
        this.$store.dispatch('database/unbindOnlineUsers')
    }
}
</script>