<template>
    <div>
        <b-field label="Menu" class="is-hidden-desktop">
            <b-select @input="EmitOptionToManagementView(MobileMenuOption)" v-model="MobileMenuOption">
                <optgroup label="Dashboard">
                    <option value="TheDashboard">{{ $t('management.sidemenu.dashboard') }}</option>
                </optgroup>
                <optgroup label="Controle de usuários" v-if="GetCurrentUser.role === 'admin'">
                    <option value="UserInsert">{{ $t('management.sidemenu.userInsert') }}</option>
                    <option value="UserModify">{{ $t('management.sidemenu.userModify') }}</option>
                    <option value="HirerInsert">{{ $t('management.sidemenu.hirerInsert') }}</option>
                </optgroup>
                <optgroup v-if="GetCurrentUser.role.toLowerCase() !== 'client'" label="Reportes">
                    <option value="OperationReportInsert">{{ $t('management.sidemenu.operationReport') }}</option>
                    <option v-if="GetCurrentUser.activeFlag.toLowerCase() != 'newmont'" value="MordorAvailability">{{ $t('Disponibilidade Mordor') }}</option>
                </optgroup>
                <optgroup label="Controle de transmissões" v-if="GetCurrentUser.role === 'admin' || GetCurrentUser.role === 'operator' || (GetCurrentUser.role === 'supervisor' && GetCurrentUser.activeFlag === 'Altave')">
                    <option value="LivestreamInsert">{{ $t('management.sidemenu.livestreamInsert') }}</option>
                </optgroup>
                <optgroup label="Controle de alertas" v-if="GetCurrentUser.role === 'admin' || GetCurrentUser.role === 'operator' || (GetCurrentUser.role === 'supervisor' && GetCurrentUser.activeFlag === 'Altave')">
                    <option value="WarningInsert">{{ $t('management.sidemenu.warningInsert') }}</option>
                </optgroup>
                <optgroup label="Relatórios" v-if="GetCurrentUser.role === 'admin' || GetCurrentUser.role === 'supervisor' || GetCurrentUser.role === 'operator' || (GetCurrentUser.role === 'supervisor' && GetCurrentUser.activeFlag === 'Altave')">
                    <option v-if="GetCurrentUser.activeFlag.toLowerCase()!='newmont'" value="ReportUserPresence">{{ $t('management.sidemenu.reportUserPresence') }}</option>
                    <option value="ReportOperationReport">{{ $t('management.sidemenu.reportOperationReport') }}</option>
                    <option v-if="GetCurrentUser.activeFlag.toLowerCase() != 'newmont'" value="MordorOperationReport">{{ $t('management.sidemenu.mordorOperationReport') }}</option>
                    <option v-if="GetCurrentUser.activeFlag.toLowerCase() != 'newmont'" value="ReportRonda">{{ $t('management.sidemenu.reportRonda') }}</option>
                </optgroup>
                <optgroup label="Ajuda">
                    <option v-if="GetCurrentUser.activeFlag.toLowerCase() != 'newmont'" value="RequestRecording">{{ $t('management.sidemenu.labels.requestrecording') }}</option>
                    <option value="AboutVersion">{{ $t('management.sidemenu.about') }}</option>
                </optgroup>
            </b-select>
        </b-field>
        <b-menu class="has-text-left is-hidden-mobile">
            <b-menu-list :label="$t('management.sidemenu.labels.dashboard')">
                <b-menu-item class=" has-text-weight-bold" :label="$t('management.sidemenu.dashboard')" @click="EmitOptionToManagementView('TheDashboard')" />
            </b-menu-list>
            <b-menu-list :label="$t('management.sidemenu.labels.userControl')" v-if="GetCurrentUser.role === 'admin'">
                <b-menu-item class=" has-text-weight-bold" :label="$t('management.sidemenu.userInsert')" @click="EmitOptionToManagementView('UserInsert')">
                </b-menu-item>
                <b-menu-item class=" has-text-weight-bold" :label="$t('management.sidemenu.userModify')" @click="EmitOptionToManagementView('UserModify')">
                </b-menu-item>
                <b-menu-item class=" has-text-weight-bold" :label="$t('management.sidemenu.hirerInsert')" @click="EmitOptionToManagementView('HirerInsert')">
                </b-menu-item>
            </b-menu-list>
            
            <b-menu-list class="has-text-weight-bold" :label="$t('management.sidemenu.labels.operationReport')" v-if="GetCurrentUser.role.toLowerCase() !== 'client'">
                <b-menu-item class=" has-text-weight-bold" :label="$t('management.sidemenu.operationReport')" @click="EmitOptionToManagementView('OperationReportInsert')" />
                <b-menu-item v-if="GetCurrentUser.activeFlag.toLowerCase()!='newmont'" class=" has-text-weight-bold" :label="$t('Disponibilidade Mordor')" @click="EmitOptionToManagementView('MordorAvailability')" />
            </b-menu-list>
            <b-menu-list class="has-text-weight-bold" :label="$t('management.sidemenu.labels.livestreamControl')" v-if="GetCurrentUser.role === 'admin' || GetCurrentUser.role === 'operator' || (GetCurrentUser.role === 'supervisor' && GetCurrentUser.activeFlag === 'Altave')">
                <b-menu-item class="has-text-weight-bold" :label="$t('management.sidemenu.livestreamInsert')" @click="EmitOptionToManagementView('LivestreamInsert')"></b-menu-item>
            </b-menu-list>
            <b-menu-list class="has-text-weight-bold" :label="$t('management.sidemenu.labels.warningControl')" v-if="GetCurrentUser.role === 'admin' || GetCurrentUser.role === 'operator' || (GetCurrentUser.role === 'supervisor' && GetCurrentUser.activeFlag === 'Altave')">
                <b-menu-item class=" has-text-weight-bold" :label="$t('management.sidemenu.warningInsert')" @click="EmitOptionToManagementView('WarningInsert')">
                </b-menu-item>
            </b-menu-list>
            <b-menu-list class="has-text-weight-bold" :label="$t('management.sidemenu.labels.reports')" v-if="GetCurrentUser.role === 'admin' || GetCurrentUser.role === 'supervisor' || (GetCurrentUser.role === 'operator' && GetCurrentUser.activeFlag === 'Altave')">
                <b-menu-item v-if="GetCurrentUser.activeFlag.toLowerCase()!='newmont'" class=" has-text-weight-bold" :label="$t('management.sidemenu.reportUserPresence')" @click="EmitOptionToManagementView('ReportUserPresence')" />
                <b-menu-item class="has-text-weight-bold" :label="$t('management.sidemenu.reportOperationReport')" @click="EmitOptionToManagementView('ReportOperationReport')" />
                <b-menu-item v-if="GetCurrentUser.activeFlag.toLowerCase()!='newmont'" class="has-text-weight-bold" :label="$t('management.sidemenu.mordorOperationReport')" @click="EmitOptionToManagementView('MordorOperationReport')" />
                <b-menu-item v-if="GetCurrentUser.activeFlag.toLowerCase()=='transpetro'" class="has-text-weight-bold" :label="$t('management.sidemenu.reportRonda')" @click="EmitOptionToManagementView('ReportRonda')" />

            </b-menu-list>
            <b-menu-list class="has-text-weight-bold" :label="$t('management.sidemenu.labels.about')">
                <b-menu-item class=" has-text-weight-bold" :label="$t('management.sidemenu.about')" @click="EmitOptionToManagementView('AboutGuide')">
                </b-menu-item>
            </b-menu-list>
        </b-menu>
    
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    name: 'SideMenu',
    data() {
        return {
            MobileMenuOption: '',
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
    },
    methods: {
        EmitOptionToManagementView(option) {
            this.$emit('clicked', option)
        }
    }
}
</script>