<template>
    <b-table :data="acessInactiveStopReports" :paginated="IsPaginated" :striped="IsStriped" :narrowed="IsNarrowed" :bordered="IsBordered" :per-page="PerPage" :current-page.sync="CurrentPage" :pagination-simple="IsPaginationSimple" :pagination-position="PaginationPosition" :default-sort-direction="DefaultSortDirection" :sort-icon="SortIcon" :sort-icon-size="SortIconSize" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page">
        <b-table-column field="Flag" label="Operação" width="30" v-slot="props" sortable>{{ props.row.flag.name}}</b-table-column>
        <b-table-column field="description" label="Motivo" width="40" v-slot="props" sortable>{{ props.row.description}}</b-table-column>
        <b-table-column field="detail" label="Observação" width="45" v-slot="props" sortable>{{ props.row.detail}}</b-table-column>
        <b-table-column field="timestamp" label="Data Inicio" width="40" v-slot="props" sortable>{{ new Date(props.row.timestamp).toLocaleDateString('pt-br') }}</b-table-column>
        <b-table-column field="timestamp" label="Inicio" width="40" v-slot="props" sortable>{{new Date(props.row.timestamp).toLocaleTimeString('pt-br')}}</b-table-column>
        <b-table-column field="end" label="Data fim" width="40" v-slot="props" sortable>{{ new Date(props.row.end).toLocaleDateString('pt-br') }}</b-table-column>
        <b-table-column field="end" label="Fim" width="40" v-slot="props" sortable>{{new Date(props.row.end).toLocaleTimeString('pt-br')}}</b-table-column>
        <b-table-column field="end" label="Duração" width="40" v-slot="props" sortable>{{elapsedCalculator(props.row.timestamp,props.row.end)}}</b-table-column>
    </b-table>
</template>
<style lang="scss">
</style>
<script>
export default {
    name: 'ReportTableStopReport',
    props: {
        acessInactiveStopReports: Array
    },
    data() {
        return {
            IsPaginated: true,
            IsPaginationSimple: false,
            IsStriped: false,
            IsBordered: false,
            IsNarrowed: false,
            PaginationPosition: 'bottom',
            DefaultSortDirection: 'desc',
            SortIcon: 'arrow-up',
            SortIconSize: 'is-small',
            CurrentPage: 1,
            PerPage: 6
        }
    },
    methods: {
        elapsedCalculator(timeStart, timeEnd) {
            let seconds = Math.floor((timeEnd - timeStart) / 1000) //in s
            let minutes = Math.floor(seconds / 60) //in minutes
            let hours
            if (minutes >= 60) {
                hours = Math.floor(minutes / 60)
                minutes = Math.floor(minutes % 60)
                return hours + 'h' + minutes + 'min'
            } else {
                return minutes + 'min'
            }
        },
    }
}
</script>