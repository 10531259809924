<template>
    <ValidationObserver v-slot="{ handleSubmit,invalid }">
        <form @submit.prevent="handleSubmit(ConfirmSendWarning)">

            <b-field position="is-left" v-if="GetCurrentUser.activeFlag.toLowerCase() === 'codeba'">
                <b-switch v-model="AreCoordinatesFieldsVisible" :rounded="false">
                    <span class="has-text-primary is-family-sans-serif has-text-weight-bold">Digitar coordenadas manualmente</span>
                </b-switch>
            </b-field>
            <div class="block" v-if="!AreCoordinatesFieldsVisible && GetCurrentUser.activeFlag.toLowerCase() === 'codeba'">
                <b-field label-position="on-border">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">Ponto</span>
                    </template>
                    <b-select v-model="WarningObject.lla" expanded>
                        <option :value="{ latitude: -12.774943, longitude: -38.492296, name:'patio-estocagem' }">Pátio de estocagem</option>
                        <option :value="{ latitude: -12.785474, longitude: -38.492808, name:'prainha' }">Prainha</option>
                        <option :value="{ latitude: -12.784042, longitude: -38.491554, name:'beira-rio' }">Comunidade Beira Rio</option>
                        <option :value="{ latitude: -12.777464, longitude: -38.500406, name:'pier-2' }">Píer 01</option>
                        <option :value="{ latitude: -12.773870, longitude: -38.500713, name:'pier-1' }">Píer 02</option>
                        <option :value="{ latitude: -12.782262, longitude: -38.499816, name:'pier-liquido' }">Píer líquido</option>
                        <option :value="{ latitude: -12.784611, longitude: -38.497848, name:'pier-gasoso' }">Píer gasoso</option>
                        <option :value="{ latitude: -12.769, longitude: -38.497, name:'portaria-1' }">Portaria 01</option>
                        <option :value="{ latitude: -12.773561, longitude: -38.490462, name:'portaria-2' }">Portaria 02</option>
                        <option :value="{ latitude: -12.769491, longitude: -38.497359, name:'portaria-3' }">Portaria 03</option>
                        <option :value="{latitude: -12.769491, longitude: -38.497359, name: 'subestacao' }">Subestação</option>
                    </b-select>
                </b-field>
                <span class="has-text-weight-bold is-size-7" v-if="WarningObject.lla.name">
                    Coordenadas de
                    <span class="has-text-info">
                        {{ WarningObject.lla.name }}
                    </span>:
                    {{ WarningObject.lla.latitude }}, {{ WarningObject.lla.longitude }}.
                </span>
            </div>
            <div class="block" v-else>
                <b-field label-position="on-border">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">Latitude</span>
                    </template>
                    <b-input v-model="WarningObject.lla.latitude">
                    </b-input>
                </b-field>

                <b-field label-position="on-border">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">Longitude</span>
                    </template>
                    <b-input v-model="WarningObject.lla.longitude">
                    </b-input>
                </b-field>
            </div>
            
            <b-field>
                <ValidationProvider rules="required"  v-slot="{ errors, valid }">                               
                    <b-field label-position="on-border"  :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Operação/Empregador</span>
                        </template>
                        <b-select v-model="Flag" expanded>
                            <option v-for="flag in GetCurrentUser.flag" :key="flag.name" :value="flag.name">{{ flag.name }}</option>
                        </b-select>
                    </b-field>
                </ValidationProvider>
            </b-field>

            <b-field>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                    <b-field label-position="on-border" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Ocorrência</span>
                        </template>
                        <b-select v-model="WarningType" expanded>
                            <option value="1">Indivíduo detectado</option>
                            <option value="2">Veículo detectado</option>
                            <option value="3">Ponto de interesse</option>
                            <option value="4">Hot Point</option>
                        </b-select>
                    </b-field>
                </ValidationProvider>
            </b-field>
            <br>
            <b-field grouped position="is-centered">
                <!-- <b-field>
                    <b-button :loading="IsTakeFrameButtonSpinning" icon-left="camera" class="is-primary" @click="TakeSnapshotFromLocalCamera()">
                        Pegar frame atual da câmera
                    </b-button>
                </b-field> -->
                <b-field>
                    <b-upload @input="ChangeImageUploadMethod('upload')" v-model="File" accept="image/jpeg">
                        <a class="button is-primary">
                            <b-icon icon="upload"></b-icon>
                            <span>Escolher imagem salva no computador</span>
                        </a>
                    </b-upload>
                </b-field>
            </b-field>
            <b-button native-type="submit" :disabled="invalid" type="is-primary" :loading="IsConfirmButtonSpinning" expanded outlined><span class="is-family-sans-serif">Confirmar</span></b-button>
        </form>
    </ValidationObserver>
</template>
<script>
import axios from 'axios'
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import { required} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
extend('required', required)
export default {
    name: 'WarningInsert',
    components:{
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            WarningObject: {
                alerts: null,
                lla: { latitude: null, longitude: null },
                idx: 'noidx',
                idxname: '',
                datetime: ''
            },
            Flag: '',
            WarningType: '',
            File: null, //Holds uploaded file by user.
            IsConfirmButtonSpinning: false, //Set to true to make the confirm button spin.
            IsTakeFrameButtonSpinning: false,
            FileBase64: null,
            ImageUploadMethod: '',
            AreCoordinatesFieldsVisible: true
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
    },
    methods: {
        ConfirmSendWarning() {
            this.IsConfirmButtonSpinning = true
            let currentEpoch = new Date().getTime()
            let currentDateString = new Date(currentEpoch).toLocaleDateString('pt-BR').split('/').join('-')
            let userFlag = this.GetCurrentUser.activeFlag.toLowerCase()
            if (this.WarningType === '1') this.WarningObject.alerts = { '1': 1, '2': 0, '3': 0, '4':0}
            else if (this.WarningType === '2') this.WarningObject.alerts = { '1': 0, '2': 1, '3': 0, '4':0 }
            else if (this.WarningType === '3') this.WarningObject.alerts = { '1': 0, '2': 0, '3': 1,'4':0 }
            else if (this.WarningType === '4') this.WarningObject.alerts = { '1': 0, '2': 0, '3': 0,'4':1 }
            this.WarningObject.datetime = currentEpoch
            this.WarningObject.idxname = this.Flag.toLowerCase()
            let databasePath = this.Flag.toLowerCase() + '/' + currentDateString + '/sauron-noidx-' + currentEpoch
            let storagePath = 'sauronimages3/'+this.Flag.toLowerCase()+'/'+currentDateString 
            let storageName = '/sauron-noidx-' + currentEpoch+'.jpg'

            if (this.ImageUploadMethod === 'upload') {
                
                this.$store.dispatch('storage/SendWarningImageToStorage', {name:storageName, file: this.File, datetime: currentEpoch, flag: userFlag,path:storagePath })
                    .then(() => {
                        this.$store.dispatch('database/PushWarningToDatabase', { path: databasePath, warningObject: this.WarningObject })
                            .then(() => {
                                this.IsConfirmButtonSpinning = false
                                this.LaunchToast('Alerta inserido com sucesso!', 'is-success')
                            })
                            .catch(error => {
                                this.IsConfirmButtonSpinning = false
                                this.LaunchToast('Erro ao inserir alerta: ' + error, 'is-danger')
                            })
                    })
            } else {
                this.$store.dispatch('storage/SendWarningImageToStorageBase64', { file: this.FileBase64, datetime: currentEpoch, flag: userFlag })
                    .then(() => {
                        this.$store.dispatch('database/PushWarningToDatabase', { path: databasePath, warningObject: this.WarningObject })
                            .then(() => {
                                this.IsConfirmButtonSpinning = false
                                this.LaunchToast('Alerta inserido com sucesso!', 'is-success')
                            })
                            .catch(error => {
                                this.IsConfirmButtonSpinning = false
                                this.LaunchToast('Erro ao inserir alerta: ' + error, 'is-danger')
                            })
                    })
            }
        },
        TakeSnapshotFromLocalCamera() {
            this.IsTakeFrameButtonSpinning = true
            let url = 'http://192.168.11.41/axis-cgi/jpg/image.cgi'
            axios.get(url, { responseType: 'arraybuffer' })
                .then(response => {
                    this.FileBase64 = Buffer.from(response.data, 'binary').toString('base64')
                    this.IsTakeFrameButtonSpinning = false
                    this.ChangeImageUploadMethod('snapshot')
                })
        },
        ChangeImageUploadMethod(method) {
            this.ImageUploadMethod = method
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000
            })
        }
    },
}
</script>