<template>
    <div class="container">
        <p class="title">Versão do HARPIA:</p>
        <p class="subtitle">{{ GetSystemVersion }}</p>
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    name: 'AboutVersion',
    computed: {
        GetSystemVersion() {
            return this.$store.getters.GetSystemVersion
        }
    }
}
</script>