<template>
    <ValidationObserver v-slot="{ handleSubmit,invalid }">
        <form @submit.prevent="handleSubmit(PushOperationReportToDatabase)">
            <div v-if="IsGeneratorComponentHidden">
                <b-field grouped>
                    <b-field label-position="on-border">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.type')}}</span>
                        </template>
                        <b-select v-model="operationReportObject.type" expanded>
                            <option value="rhro">{{$t('opReport.rhro')}}</option>
                            <option value="rero">{{$t('opReport.rero')}}</option>
                        </b-select>
                    </b-field>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">Status</span>
                        </template>
                        <b-select v-model="operationReportObject.aerostatInfo.status" expanded>
                            <option value="flying-no-problem" @input="ClearFields()">
                                {{$t('opReport.opStatus.flyNoProblemn')}}
                            </option>
                            <option value="flying-with-problem" @input="ClearFields()">
                                {{$t('opReport.opStatus.flyWithProblemn')}}
                            </option>
                            <option value="anchored" @input="ClearFields()">
                               {{$t('opReport.opStatus.aerostatDock')}}
                            </option>
                            <option value="takeoff" @input="ClearFields()">
                                {{$t('opReport.opStatus.startFlight')}}
                            </option>
                            <option value="partial" @input="ClearFields()">
                                {{$t('opReport.opStatus.altitudeChange')}}
                            </option>
                            <option value="landing" @input="ClearFields()">
                                {{$t('opReport.opStatus.docking')}}
                            </option>
                        </b-select>
                    </b-field>
                </b-field>
                <b-field grouped>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.author')}}</span>
                        </template>
                        <b-input v-model="operationReportObject.author" expanded>
                        </b-input>
                    </b-field>
                    <b-field label-position="on-border">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.dateTime')}}</span>
                        </template>
                        <b-datetimepicker v-model="operationReportObject.timestamp"></b-datetimepicker>
                    </b-field>
                </b-field>
                <b-field grouped>
                    <b-field label-position="on-border" v-if="operationReportObject.aerostatInfo.status === 'flying-with-problem'" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.problemOrigin')}}</span>
                        </template>
                        <b-select v-model="operationReportObject.problemMotive" expanded>
                            <option value="external">{{$t('opReport.factorExtern')}}</option>
                            <option value="internal">{{$t('opReport.factorIntern')}}</option>
                        </b-select>
                    </b-field>
                    <b-field label-position="on-border" v-if="operationReportObject.aerostatInfo.status === 'anchored' || operationReportObject.aerostatInfo.status === 'landing'" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.dockingCause')}}</span>
                        </template>
                        <b-select v-model="operationReportObject.aerostatInfo.stoppedFlightCondition" expanded>
                            <option value="meteorology">{{$t('opReport.meteorologic')}}</option>
                            <option value="maintenance">{{$t('opReport.repair')}}</option>
                            <option value="pre-flight">{{$t('opReport.preFly')}}</option>
                            <option value="training">{{$t('opReport.training')}}</option>
                            <option value="non-working-hours">{{$t('opReport.offWorkers')}}</option>
                            <option value="misc-external">{{$t('opReport.othersExt')}}</option>
                            <option value="misc-internal">{{$t('opReport.othersInt')}}</option>
                        </b-select>
                    </b-field>
                </b-field>
                <b-field label-position="on-border" v-if="operationReportObject.aerostatInfo.stoppedFlightCondition === 'maintenance'">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">{{$t('opReport.repairType')}}</span>
                    </template>
                    <b-select v-model="operationReportObject.maintenanceInfo.type" expanded>
                        <option value="corrective">{{$t('opReport.repairCorrective')}}</option>
                        <option value="preventive">{{$t('opReport.repairPrevent')}}</option>
                    </b-select>
                </b-field>
                <b-field label-position="on-border" v-if="operationReportObject.aerostatInfo.stoppedFlightCondition === 'maintenance'">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">{{$t('opReport.equip')}}</span>
                    </template>
                    <MaintenanceAutocomplete v-model="operationReportObject.maintenanceInfo.maintenanceEquipment" />
                </b-field>
                <b-field grouped>
                    <b-field label-position="on-border" v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.monitoringRoutine')}}</span>
                        </template>
                        <b-select v-model="operationReportObject.monitoringStatus" expanded>
                            <option value="tour">{{$t('opReport.monitorStatus.automaticTour')}}</option>
                            <option value="manual">{{$t('opReport.monitorStatus.manualMonitoring')}}</option>
                        </b-select>
                    </b-field>
                    <b-field label-position="on-border" v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.liveStream')}}</span>
                        </template>
                        <b-select v-model="operationReportObject.livestreamStatus" expanded>
                            <option :value="true">{{$t('opReport.liveStatus.operatingLive')}}</option>
                            <option :value="false">{{$t('opReport.liveStatus.operatingNoLive')}}</option>
                        </b-select>
                    </b-field>
                    <b-field label-position="on-border" v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.cableReleased')}}</span>
                        </template>
                        <b-field>
                            <b-input v-model="operationReportObject.aerostatInfo.altitude" type="number" expanded>
                            </b-input>
                        </b-field>
                    </b-field>
                </b-field>
                <b-field v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'" grouped>
                    <b-field :type="{'is-success':  operationReportObject.aerostatInfo.flightHours,'is-warning': !operationReportObject.aerostatInfo.flightHours}" label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.hoursContinuos')}}</span>
                        </template>
                        <b-input v-model="operationReportObject.aerostatInfo.flightHours" type="number">
                        </b-input>
                    </b-field>
                    <b-field :type="{'is-success':  operationReportObject.aerostatInfo.flightMinutes,'is-warning': !operationReportObject.aerostatInfo.flightMinutes}" label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.minutesContinuos')}}</span>
                        </template>
                        <b-input v-model="operationReportObject.aerostatInfo.flightMinutes" type="number">
                        </b-input>
                    </b-field>
                </b-field>
                <b-field v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'" grouped>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">PLC - Upload</span>
                        </template>
                        <b-input v-model="operationReportObject.plcTransmission.upload" type="number">
                        </b-input>
                    </b-field>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">PLC - Download</span>
                        </template>
                        <b-input v-model="operationReportObject.plcTransmission.download" type="number">
                        </b-input>
                    </b-field>
                </b-field>
                <b-field v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'" grouped>
                    <b-field label-position="on-border">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.modelCam')}}</span>
                        </template>
                        <b-select v-model="operationReportObject.payloadInfo.cameraModel">
                            <option v-for="(camera, index) in cameraModels" :key="index" :value="camera"> {{ camera.toUpperCase() }} </option>
                        </b-select>
                    </b-field>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif"> {{$t('opReport.irCooler')}}</span>
                        </template>
                        <b-select v-model="operationReportObject.payloadInfo.irCoolerStatus" expanded>
                            <option value="on">{{$t('opReport.irStatus.on')}}</option>
                            <option value="off">{{$t('opReport.irStatus.off')}}</option>
                            <option value="none">{{$t('opReport.irStatus.dontExist')}}</option>
                        </b-select>
                    </b-field>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.snGondola')}}</span>
                        </template>
                        <b-input v-model="operationReportObject.payloadInfo.gondola.serialNumber" expanded>
                        </b-input>
                    </b-field>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.pnGondola')}}</span>
                        </template>
                        <b-input v-model="operationReportObject.payloadInfo.gondola.partNumber" expanded>
                        </b-input>
                    </b-field>
                </b-field>
                <b-field v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'" grouped>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.extEnvelope')}}</span>
                        </template>
                        <b-input v-model="operationReportObject.aerostatInfo.nylon.number"></b-input>
                    </b-field>
                    <b-field label-position="on-border">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.dateInstallNylon')}}</span>
                        </template>
                        <b-datepicker position="is-bottom-left" v-model="operationReportObject.aerostatInfo.nylon.timestamp"></b-datepicker>
                    </b-field>
                </b-field>
                <b-field v-if="operationReportObject.aerostatInfo.status === 'flying-no-problem' || operationReportObject.aerostatInfo.status === 'flying-with-problem' || operationReportObject.aerostatInfo.status === 'partial' || operationReportObject.aerostatInfo.status === 'takeoff'" grouped>
                    <b-field label-position="on-border" expanded>
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.tpu')}}</span>
                        </template>
                        <b-input v-model="operationReportObject.aerostatInfo.tpu.number"></b-input>
                    </b-field>
                    <b-field label-position="on-border">
                        <template slot="label">
                            <span class="has-text-primary is-family-sans-serif">{{$t('opReport.dateInstallTPU')}}</span>
                        </template>
                        <b-datepicker position="is-bottom-left" v-model="operationReportObject.aerostatInfo.tpu.timestamp"></b-datepicker>
                    </b-field>
                </b-field>
                <b-field label-position="on-border">
                    <template slot="label">
                        <span class="has-text-primary is-family-sans-serif">{{$t('opReport.report')}}</span>
                    </template>
                    <b-input v-model="operationReportObject.notes" type="textarea" maxlength="600">
                    </b-input>
                </b-field>
                <WindCriteriaInsert v-model="operationReportObject.weatherDataObject" />
                <b-button :disabled="invalid" expanded type="is-primary" :loading="IsConfirmButtonSpinning" @click="PushOperationReportToDatabase()" outlined><span class="is-family-sans-serif">{{$t('confirm')}}</span></b-button>
            </div>
            <div v-else>
                <OperationReportGenerator :OperationReportObject="operationReportObject" />
            </div>
        </form>
    </ValidationObserver>
</template>
<style lang="scss" scoped>
</style>
<script>
import { ValidationObserver } from 'vee-validate'
import WindCriteriaInsert from '@/components/management/WindCriteriaInsert.vue'
import OperationReportGenerator from '@/components/management/OperationReportGenerator.vue'
import MaintenanceAutocomplete from '@/components/management/MaintenanceAutocomplete.vue'
export default {
    name: 'OperationReportInsert',
    components: {
        WindCriteriaInsert,
        OperationReportGenerator,
        MaintenanceAutocomplete,
        ValidationObserver
    },
    data() {
        return {
            IsGeneratorComponentHidden: true,
            IsConfirmButtonSpinning: false,
            LastOperationReport: null,
            cameraModels: [],
            operationReportObject: {
                aerostatInfo: {
                    status: 'flying-no-problem',
                    stoppedFlightCondition: '',
                    altitude: '',
                    nylon: { number: '', timestamp: null },
                    tpu: { number: '', timestamp: null },
                    flightHours: '',
                    flightMinutes: ''
                },
                payloadInfo: {
                    cameraModel: '',
                    irCoolerStatus: '',
                    gondola: { serialNumber: '', partNumber: '' }
                },
                maintenanceInfo: {
                    type: '',
                    maintenanceEquipment: ''
                },
                problemMotive: '',
                monitoringStatus: '',
                livestreamStatus: '',
                plcTransmission: { upload: '', download: '' },
                notes: '',
                type: '',
                timestamp: '',
                author: '',
                operation: '',
                secondsToFillForm: null,
                weatherDataObject: null,
            },
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetStopReport() {
            return this.$store.getters['database/GetStopReport']
        },
        GetCurrentSelectedAerostatStatus() {
            return this.operationReportObject.aerostatInfo.status
        }
    },
    watch: {
        GetCurrentSelectedAerostatStatus: {
            handler(status) {
                if (status === 'anchored' || status === 'landing') this.ClearFields()
                else this.FillFormWithLastReportInfo()
            }
        }
    },
    methods: {
        PushOperationReportToDatabase() {
            // this.$gtag.event('inserted_rhrorero', { 'event_category': 'operational' })
            this.IsGeneratorComponentHidden = false
            this.operationReportObject.timestamp = this.operationReportObject.timestamp.getTime()
            this.operationReportObject.secondsToFillForm = (new Date().getTime() - this.operationReportObject.secondsToFillForm) / 1000

            let elapsedTime = this.GetElapsedTime(this.LastOperationReport.timestamp, { hours: this.LastOperationReport.aerostatInfo.flightHours, minutes: this.LastOperationReport.aerostatInfo.flightMinutes })
            this.ComputeOperationHours(elapsedTime.timeDifference, this.LastOperationReport.aerostatInfo.status)
            if (
                this.operationReportObject.aerostatInfo.status === 'flying-no-problem' ||
                this.operationReportObject.aerostatInfo.status === 'flying-with-problem' ||
                this.operationReportObject.aerostatInfo.status === 'partial' ||
                this.operationReportObject.aerostatInfo.status === 'takeoff'
            ) {
                if (this.operationReportObject.aerostatInfo.nylon.timestamp) {
                    this.operationReportObject.aerostatInfo.nylon.timestamp = this.operationReportObject.aerostatInfo.nylon.timestamp.getTime()
                }
                if (this.operationReportObject.aerostatInfo.nylon.timestamp) {
                    this.operationReportObject.aerostatInfo.tpu.timestamp = this.operationReportObject.aerostatInfo.tpu.timestamp.getTime()
                }

            }
            let modifiedDate = new Date(this.operationReportObject.timestamp).toLocaleDateString('pt-br').split('/').join('-')
            this.$store.dispatch('database/PushToDatabase', {
                path: '/operation-notes/' + this.operationReportObject.operation.toLowerCase() + '/' + modifiedDate,
                object: { operationReportObject: this.operationReportObject }
            })
            this.$store.dispatch('database/SetOnDatabase', {
                path: '/operation-notes/' + this.operationReportObject.operation.toLowerCase() + '/date-index/' + modifiedDate,
                object: { operationReportObject: this.operationReportObject }
            })

            if (this.operationReportObject.aerostatInfo.status === 'anchored' || this.operationReportObject.aerostatInfo.status === 'landing') {
                this.InsertStopReport(this.operationReportObject.aerostatInfo.stoppedFlightCondition)
            } else {
                if (!this.operationReportObject.livestreamStatus) {
                    this.InsertStopReport('no-livestream')
                } else {
                    let stopReport = this.GetStopReport
                    if (!stopReport.detail) return
                    this.RemoveStopReport(stopReport)
                }
            }
        },
        RemoveStopReport(stopReport) {
            stopReport.end = this.operationReportObject.timestamp
            let modifiedDate = new Date(stopReport.timestamp).toLocaleDateString('pt-br').split('/').join('-')
            let inactiveStopReportPath = 'stop-reports/inactive/' + stopReport.flag.name.toLowerCase() + '/' + modifiedDate
            let activeStopReportPath = 'stop-reports/active/' + stopReport.flag.name.toLowerCase()

            this.$store.dispatch('database/PushToDatabase', { path: inactiveStopReportPath, object: stopReport })
                .then(() => { this.$store.dispatch('database/RemoveFromDatabase', { path: activeStopReportPath, object: stopReport }) })
        },
        InsertStopReport(condition) {
            let stopReport = {
                flag: { name: this.GetCurrentUser.activeFlag },
                description: condition,
                detail: this.operationReportObject.notes,
                timestamp: this.operationReportObject.timestamp
            }
            this.$store.dispatch('database/SetOnDatabase', {
                path: 'stop-reports/active/' + stopReport.flag.name.toLowerCase(),
                object: stopReport
            })
        },
        FillFormWithLastReportInfo() {
            let lastOperationReport = null
            this.$store.dispatch('database/GetFromDatabase', {
                path: 'operation-notes/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/date-index',
                returnSnapshotVal: false
            }).then(snapshot => {
                let availableDatesArray = []
                let mostRecentDate = ''
                for (let date in snapshot.val()) { //For each date fetched.
                    let modifiedDate = date.split('-') //Split dates by '-' delimiter to later save them on dd/mm/yyyy format.
                    availableDatesArray.push(new Date(modifiedDate[1] + '/' + modifiedDate[0] + '/' + modifiedDate[2])) //Push date to local array.
                }
                availableDatesArray.sort((dateOne, dateTwo) => { return dateOne - dateTwo }) //Sort local array of available dates on chronological order.
                mostRecentDate = availableDatesArray[availableDatesArray.length - 1].toLocaleDateString('pt-br').split('/').join('-')
                this.$store.dispatch('database/GetLimitedFromDatabase', {
                    path: 'operation-notes/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/' + mostRecentDate,
                    limit: 1,
                    returnSnapshotVal: false
                }).then(snapshot => {
                    lastOperationReport = snapshot.val()
                    lastOperationReport = lastOperationReport[Object.keys(lastOperationReport)].operationReportObject
                    this.LastOperationReport = lastOperationReport
                    if (
                        lastOperationReport.aerostatInfo.status === 'flying-no-problem' ||
                        lastOperationReport.aerostatInfo.status === 'flying-with-problem' ||
                        lastOperationReport.aerostatInfo.status === 'partial' ||
                        lastOperationReport.aerostatInfo.status === 'takeoff'
                    ) {
                        if (lastOperationReport.timestamp) {
                            let elapsedTime = this.GetElapsedTime(lastOperationReport.timestamp, { hours: lastOperationReport.aerostatInfo.flightHours, minutes: lastOperationReport.aerostatInfo.flightMinutes })
                            this.operationReportObject.aerostatInfo.flightMinutes = elapsedTime.minutes
                            this.operationReportObject.aerostatInfo.flightHours = elapsedTime.hours
                        }
                        this.operationReportObject.aerostatInfo.altitude = lastOperationReport.aerostatInfo.altitude
                        this.operationReportObject.monitoringStatus = lastOperationReport.monitoringStatus
                        this.operationReportObject.livestreamStatus = lastOperationReport.livestreamStatus
                        this.operationReportObject.plcTransmission.upload = lastOperationReport.plcTransmission.upload
                        this.operationReportObject.plcTransmission.download = lastOperationReport.plcTransmission.download
                        this.operationReportObject.payloadInfo.cameraModel = lastOperationReport.payloadInfo.cameraModel || ''
                        this.operationReportObject.payloadInfo.irCoolerStatus = lastOperationReport.payloadInfo.irCoolerStatus || ''
                        this.operationReportObject.payloadInfo.gondola.partNumber = lastOperationReport.payloadInfo.gondola.partNumber || ''
                        this.operationReportObject.payloadInfo.gondola.serialNumber = lastOperationReport.payloadInfo.gondola.serialNumber || ''
                        this.operationReportObject.aerostatInfo.nylon.number = lastOperationReport.aerostatInfo.nylon.number || ''
                        this.operationReportObject.aerostatInfo.tpu.number = lastOperationReport.aerostatInfo.tpu.number || ''
                        // this.operationReportObject.aerostatInfo.nylon.timestamp = new Date(lastOperationReport.aerostatInfo.nylon.timestamp)
                        // this.operationReportObject.aerostatInfo.tpu.timestamp = new Date(lastOperationReport.aerostatInfo.tpu.timestamp)

                        if (!lastOperationReport.aerostatInfo.nylon.timestamp) {
                            this.operationReportObject.aerostatInfo.nylon.timestamp = null
                        } else {
                            this.operationReportObject.aerostatInfo.nylon.timestamp = new Date(lastOperationReport.aerostatInfo.nylon.timestamp)
                        }
                        if (!lastOperationReport.aerostatInfo.tpu.timestamp) {
                            this.operationReportObject.aerostatInfo.tpu.timestamp = null
                        } else {
                            this.operationReportObject.aerostatInfo.tpu.timestamp = new Date(lastOperationReport.aerostatInfo.tpu.timestamp)
                        }
                    }
                })
            })
        },
        ClearFields() {
            this.operationReportObject.aerostatInfo.stoppedFlightCondition = ''
            this.operationReportObject.problemMotive = ''
            this.operationReportObject.maintenanceInfo.type = ''
            this.operationReportObject.maintenanceInfo.maintenanceEquipment = ''
            this.operationReportObject.monitoringStatus = ''
            this.operationReportObject.livestreamStatus = ''
            this.operationReportObject.plcTransmission = { upload: '', download: '' }
            this.operationReportObject.aerostatInfo.altitude = ''
            this.operationReportObject.aerostatInfo.flightHours = ''
            this.operationReportObject.aerostatInfo.flightMinutes = ''

            this.operationReportObject.payloadInfo.cameraModel = ''
            this.operationReportObject.payloadInfo.irCoolerStatus = ''
            this.operationReportObject.payloadInfo.gondola.partNumber = ''
            this.operationReportObject.payloadInfo.gondola.serialNumber = ''
            this.operationReportObject.aerostatInfo.nylon.number = ''
            this.operationReportObject.aerostatInfo.tpu.serialNumber = ''
            this.operationReportObject.aerostatInfo.nylon.number = ''
            this.operationReportObject.aerostatInfo.tpu.timestamp = ''
        },
        GetElapsedTime(timestamp, timeString) {
            let timeDifference = (this.operationReportObject.timestamp - timestamp) / 1000
            let elapsedTime = {}
            let hoursPassedRaw = timeDifference / (60 * 60) + parseInt(timeString.minutes) / 60 + parseInt(timeString.hours)
            elapsedTime.timeDifference = timeDifference
            elapsedTime.hours = Math.floor(hoursPassedRaw)
            elapsedTime.minutes = Math.floor((hoursPassedRaw - elapsedTime.hours) * 60)
            return elapsedTime
        },
        ComputeOperationHours(timeToAdd, aerostatStatus) {
            let timeOnDatabase = { 'flying-no-problem': 0, 'anchored': 0, 'fyling-with-problem': 0 } //Default object to store elapsed flying/anchored time in case there's none on database.
            this.$store.dispatch('database/GetFromDatabase', { //Get total time saved on database.
                path: 'operation-notes-hours/' + this.GetCurrentUser.activeFlag.toLowerCase(),
                returnSnapshotVal: false
            }).then(snapshot => {
                if (snapshot.exists()) timeOnDatabase = snapshot.val() //If there's in fact time stored on database, populate local variable with it.
                if (aerostatStatus === 'takeoff' || aerostatStatus === 'partial') aerostatStatus = 'flying-no-problem'
                if (aerostatStatus === 'landing') aerostatStatus === 'anchored'
                // console.log(aerostatStatus)
                // console.log(timeOnDatabase)
                // console.log(timeOnDatabase[aerostatStatus])
                // console.log('Tempo no database: ' + Math.floor(timeOnDatabase[aerostatStatus] / 60))
                // console.log('Tempo para adicionar no database: ' + timeToAdd)
                // console.log('Tempo somado: ' + (timeOnDatabase[aerostatStatus] + timeToAdd))
                timeOnDatabase[aerostatStatus] = timeOnDatabase[aerostatStatus] + timeToAdd //Add elapsed time since last report to total time saved on database.
                this.$store.dispatch('database/SetOnDatabase', {
                    path: '/operation-notes-hours/' + this.GetCurrentUser.activeFlag.toLowerCase(),
                    object: timeOnDatabase
                })
            })
        }
    },
    created() {
        this.operationReportObject.author = this.GetCurrentUser.name
        this.operationReportObject.operation = this.GetCurrentUser.activeFlag
        this.operationReportObject.timestamp = new Date()
        this.operationReportObject.secondsToFillForm = new Date().getTime()

        this.$store.dispatch('database/GetFromDatabase', { path: 'maintenance/cameras', returnSnapshotVal: false })
            .then(snapshot => {
                snapshot.forEach(childSnapshot => { this.cameraModels.push(childSnapshot.val().name) })
            })

        this.FillFormWithLastReportInfo()
        if (this.operationReportObject.timestamp.getMinutes() !== 0) this.operationReportObject.type = 'rero'
        else this.operationReportObjectReport.type = 'rhro'
    }
}
</script>