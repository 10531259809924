<template>
    <div class="columns is-multiline">
        <div class="column has-text-left">
            <b-field label-position="on-border" grouped group-multiline>
                <template slot="label">
                    <span class="has-text-primary is-family-sans-serif">Selecione a(s) data(s):</span>
                </template>
                <b-datepicker v-model="SelectedDate" :month-names="MonthNames" :day-names="DayNames" :selectable-dates="WarningsDatesAvailable" icon="calendar-alt" trap-focus multiple></b-datepicker>
                <p class="control">
                    <b-button class="button is-primary" @click="ConfirmSelectedDates" expanded outlined>
                        <span class="is-family-sans-serif">Confirmar</span>
                    </b-button>
                </p>
                <p class="control">
                    <b-button class="button is-warning" @click="GeneratePDFReport" v-if="OperationReports.length" icon-right="file-pdf" expanded outlined>
                        <span class="is-family-sans-serif">Baixar relatório</span>
                    </b-button>
                </p>
                <p class="control">
                    <b-button class="button is-warning" @click="GenerateCsv" v-if="OperationReports.length" icon-left="file-csv"  expanded outlined>
                        <span class="is-family-sans-serif">CSV</span>
                    </b-button>
                </p>
            </b-field>
        </div>
        <div class="column is-full text-center" v-if="OperationReports.length">
            <ReportTableOperationReport :OperationReports="OperationReports" />
        </div>
    </div>
</template>
<style lang="scss" scope></style>
<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ReportTableOperationReport from '@/components/management/ReportTableOperationReport.vue'
export default {
    name: 'ReportOperationReport',
    components: {
        ReportTableOperationReport
    },
    data() {
        return {
            isEmpty: false,
            SelectedDate: [], //Bound to datepicker component to hold user selected date to see logs.
            MonthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            DayNames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            WarningsDatesAvailable: [],
            OperationReports: [],
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
    },
    watch: {
        GetCurrentUser: {
            handler() {
                this.SelectedDate = []
                this.OperationReports = [] //Empty local warnings array so warnings from one operation don't mix with another.
                this.GetOperationReportsDatesAvailable() //Call method to check on dates available for the new operation selected.
            },
            deep: true //Watch for changes on the entire user object, not just its first level.
        }
    },
    methods: {
        GetOperationReportsDatesAvailable() {
            this.$store.dispatch('database/GetFromDatabase', {
                path: 'operation-notes/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/date-index/',
                returnSnapshotVal: true
            }).then(snapshot => {          
                   
                let operationReportDatesArray = []
                for (let date in snapshot) {
                    let modifiedDate = date.split('-')
                    operationReportDatesArray.push(new Date(modifiedDate[1] + '/' + modifiedDate[0] + '/' + modifiedDate[2]))
                }
                operationReportDatesArray.sort((dateOne, dateTwo) => { return dateOne - dateTwo })
                this.WarningsDatesAvailable = operationReportDatesArray
            })
        },
        //Search all the information of the selected dates and insert it in the Array Logs
        ConfirmSelectedDates() {
            this.OperationReports = []
            this.SelectedDate.sort((dateOne, dateTwo) => { return dateOne - dateTwo })
            this.SelectedDate.forEach(date => {
                let modifiedDate = date.toLocaleDateString('pt-br').split('/').join('-')
                this.$store.dispatch('database/GetFromDatabase', {
                    path: 'operation-notes/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/' + modifiedDate,
                    returnSnapshotVal: false
                }).then(snpashot => { snpashot.forEach(childSnapshot => { this.OperationReports.push(childSnapshot.val()) }) })
            })
        },
        //Used to generate a PDF report using information created in the above methods about HARPIA usage.
        GeneratePDFReport() {
            try {
                const doc = new jsPDF({
                    orientation: 'l',
                    unit: 'mm',
                    format: 'a4',
                    putOnlyUsedFonts: true,
                    floatPrecision: 16
                })
                const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()

                const datesSelected = []
                this.SelectedDate.forEach(date => { datesSelected.push(date.toLocaleDateString('pt-br')) })

                let img = new Image()
                img.src = require('@/assets/altavelogo_gde.png')
                //(imageData, format, x, y, width, height, alias, compression, rotation)
                doc.addImage(img, 'PNG', 9, 3, 80, 24)

                let reportPeriodRange = ''
                if (datesSelected.length > 1) { reportPeriodRange = datesSelected[0] + ' - ' + datesSelected[datesSelected.length - 1] } else { reportPeriodRange = datesSelected[0] }

                let anchoredTime = 0
                let cont = 0
                while (cont < this.OperationReports.length - 1) {
                    if (this.OperationReports[cont].operationReportObject.aerostatInfo.status == 'anchored') {
                        anchoredTime = anchoredTime + (this.OperationReports[cont + 1].operationReportObject.timestamp - this.OperationReports[cont].operationReportObject.timestamp)
                    }
                    cont++
                }

                doc.setFontSize(25).setFontType('bold').text('Relatório de disponibilidade de voo', pageWidth / 2, 35, 'center')
                doc.setFontSize(6).setFontType('normal').text('Relatório gerado em: ' + new Date().toLocaleDateString() + ' as ' + new Date().toLocaleTimeString(), pageWidth - 45, 5, 'left');

                doc.setFontSize(20).setFontType('bold').text('Operação: ', pageWidth / 2, 42, 'right')
                doc.setTextColor(15, 76, 129).text(this.GetCurrentUser.activeFlag, pageWidth / 2, 42, 'left')

                doc.setTextColor(0, 0, 0).setFontSize(15).setFontType('bold').text('Período de serviço: ', pageWidth / 2, 51, 'right')
                doc.setFontType('normal').text(reportPeriodRange, pageWidth / 2, 51, 'left')

                doc.setFontType('bold').text('Total do período de serviço: ', pageWidth / 2, 58, 'right')
                doc.setFontType('normal').text(this.CalculeElapsedCalculator(this.OperationReports[0].operationReportObject.timestamp, this.OperationReports[this.OperationReports.length - 1].operationReportObject.timestamp), pageWidth / 2, 58, 'left')

                doc.setFontType('bold').text('Total ancorado: ', pageWidth / 2, 65, 'right')
                doc.setFontType('normal').text(this.CalculeElapsedCalculator(0, anchoredTime), pageWidth / 2, 65, 'left')

                doc.setFontType('bold').text('Total voando: ', pageWidth / 2, 72, 'right')
                doc.setFontType('normal').text(this.CalculeElapsedCalculator(anchoredTime, (this.OperationReports[this.OperationReports.length - 1].operationReportObject.timestamp - this.OperationReports[0].operationReportObject.timestamp)), pageWidth / 2, 72, 'left')

                let rowTable = []
                for (let prop in this.OperationReports) {
                    let author = this.OperationReports[prop].operationReportObject.author
                    let type = this.OperationReports[prop].operationReportObject.type
                    let raw_status = this.OperationReports[prop].operationReportObject.aerostatInfo.status
                    // Translate type of status
                    let status = raw_status == 'anchored' ? this.GetStoppedFlightCondition(this.OperationReports[prop].operationReportObject.aerostatInfo.stoppedFlightCondition) : this.GetTranslatedStatus(raw_status)
                    
                    let meteorology = this.GetMeteorologyData(this.OperationReports[prop].operationReportObject, '\n')
                    let meteorologyDataGeocam = meteorology.DataGeocam
                    let meteorologyDataLocalStation = meteorology.DataLocalStation
                    let meteorologyDataWindy = meteorology.DataWindy
                    let meteorologyDataTupan = meteorology.DataTupan
                    let meteorologyDataCemaden = meteorology.DataCemaden
                    let meteorologyDataLocal = meteorology.DataLocal
                    let meteorologyLightningDetector = meteorology.LightningDetector
                    
                    let information = this.OperationReports[prop].operationReportObject.notes

                    let monitoring = this.OperationReports[prop].operationReportObject.monitoringStatus == 'tour' ? monitoring = 'Autom.' : monitoring = 'Manual'

                    let stream = this.OperationReports[prop].operationReportObject.liveStreamStatus ? stream = 'Ao vivo' : stream = 'Sem transmissão'

                    let plc = {}
                    this.OperationReports[prop].operationReportObject.plcTransmission.upload ? plc = { up: this.OperationReports[prop].operationReportObject.plcTransmission.upload, down: this.OperationReports[prop].operationReportObject.plcTransmission.download } : plc = { up: 'N/I', down: 'N/I' }

                    let altitude = this.OperationReports[prop].operationReportObject.aerostatInfo.altitude ? altitude = this.OperationReports[prop].operationReportObject.aerostatInfo.altitude + 'm' : altitude = 'N/I'

                    let continousFlight = this.OperationReports[prop].operationReportObject.aerostatInfo.flightHours ? this.AddZero(continousFlight = this.OperationReports[prop].operationReportObject.aerostatInfo.flightHours) + ':' + this.AddZero(this.OperationReports[prop].operationReportObject.aerostatInfo.flightMinutes) : continousFlight = 'N/I'

                    let date = 'N/I'
                    let time = 'N/I'

                    if (this.OperationReports[prop].operationReportObject.timestamp) {
                        date = new Date(this.OperationReports[prop].operationReportObject.timestamp).toLocaleDateString('pt-br').substring(0, 5) // dd/mm
                        time = new Date(this.OperationReports[prop].operationReportObject.timestamp).toLocaleTimeString('pt-br').substring(0, 5) // hh:mm
                    }

                    rowTable.push([type, author, status, date, time, information, monitoring, stream, 'up:' + plc.up + '\ndown:' + plc.down, altitude, continousFlight, meteorologyDataGeocam, meteorologyDataLocalStation, meteorologyDataWindy, meteorologyDataTupan, meteorologyDataCemaden, meteorologyDataLocal, meteorologyLightningDetector])
                }
                rowTable.reverse()
                doc.autoTable({
                    startY: 80,
                    rowPageBreak: 'avoid',
                    margin: { left: 10 },

                    head: [
                        [{ content: 'Resumo das atividades (' + reportPeriodRange + ')', colSpan: 11, styles: { halign: 'center', fillColor: [22, 160, 133], textColor: [255, 255, 255], fontSize: 10 } },
                            { content: 'Dados Meteorologicos', colSpan: 5, styles: { align: 'center', fillColor: [22, 160, 133], textColor: [255, 255, 255], fontSize: 10 } }
                        ],
                        [{ content: 'Tipo', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Autor', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Status', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Data', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Hora', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Informações', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Ronda', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Transmissão', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'PLC', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Altitude', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Tempo de \nvoo contínuo', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Geocam', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Estação\nLocal', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Windy', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Tupan', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Cemaden', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Anemômetro', styles: { halign: 'center', fontSize: 9 } },
                            { content: 'Detector \nde raios', styles: { halign: 'center', fontSize: 9 } }
                        ]
                    ],
                    body: rowTable,
                    columnStyles: {
                        0: { cellWidth: 10, fontSize: 7.5 }, //tipo
                        1: { cellWidth: 18, fontSize: 7.5 }, //autor
                        2: { cellWidth: 18, fontSize: 7.5 }, //status
                        3: { cellWidth: 12, fontSize: 7.5 }, //data
                        4: { cellWidth: 12, fontSize: 7.5 }, //hora
                        5: { cellWidth: 26, fontSize: 7.5 }, //informações                  
                        6: { cellWidth: 14, fontSize: 7.5 }, //Ronda
                        7: { cellWidth: 21, fontSize: 7.5 }, //Transmissão
                        8: { cellWidth: 16, fontSize: 7.5 }, //PLC
                        9: { cellWidth: 18, fontSize: 7.5 }, //Altitude
                        10: { cellWidth: 25, fontSize: 7.5 }, //tempo de voo
                        11: { cellWidth: 22, fontSize: 7.5 }, // Geocam
                        12: { cellWidth: 23, fontSize: 7.5 }, // Local
                        13: { cellWidth: 18, fontSize: 7.5 }, // Windy
                        14: { cellWidth: 12, fontSize: 7.5 }, // Tupan
                        15: { cellWidth: 16, fontSize: 7.5 }, // Cemaden
                        16: { cellWidth: 15, fontSize: 7.5 }, // Anemometro
                        17: { cellWidth: 20, fontSize: 7.5 }, // lightningDetector
                    },
                    theme: 'striped',
                    styles: { overflow: 'linebreak', cellWidth: 'wrap', lineColor: [0, 0, 0], lineWidth: 0.1, halign: 'center', fontSize: 10, font: 'times', valign: 'middle' },
                })
                doc.save('Relatório de disponibilidade de voo  (' + reportPeriodRange + ').pdf') //Saves created table to PDF.              
            } catch (error) {
                this.LaunchToast('Não há dados suficientes para gerar relatório!' + error, 'is-danger')
            }
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000,
            })
        },
        GetStoppedFlightCondition(stoppedFlightCondition) {
            let stoppedFlightConditionText = ''
            switch (stoppedFlightCondition) {
                case 'meteorology':
                    stoppedFlightConditionText = 'Meteorologia'
                    break
                case 'maintenance':
                    stoppedFlightConditionText = 'Manutenção'
                    break
                case 'pre-flight':
                    stoppedFlightConditionText = 'Pré-voo'
                    break
                case 'training':
                    stoppedFlightConditionText = 'Treinamento'
                    break
                case 'non-working-hours':
                    stoppedFlightConditionText = 'Sem Expediente'
                    break
                case 'misc-external':
                    stoppedFlightConditionText = 'Fatores Externos'
                    break
                case 'misc-internal':
                    stoppedFlightConditionText = 'Fatores Internos'
                    break
                default:
                    break
            }
            return 'Ancorado\n(' + stoppedFlightConditionText + ')'
        },
        // For add zero left in numbers just 1 digit
        AddZero(number) {
            number < 10 ? number = '0' + number : number
            number.length > 2 ? number = number.substring(1) : number
            return number
        },
        CalculeElapsedCalculator(timeStart, timeEnd) {
            let seconds = Math.floor((timeEnd - timeStart) / 1000) //in s
            let minutes = Math.floor(seconds / 60) //in minutes
            let hours
            if (minutes >= 60) {
                hours = Math.floor(minutes / 60)
                minutes = Math.floor(minutes % 60)
                if (minutes < 10) { return hours + 'h 0' + minutes + 'min' } else { return hours + 'h ' + minutes + 'min' }
            } else {
                if (minutes < 10) { return '0' + minutes + 'min' } else { return minutes + 'min' }
            }
        },
        GenerateCsv(){
            let initialDate = new Date(this.SelectedDate[0]).toLocaleDateString('pt-BR')
            let finalDate = new Date(this.SelectedDate[this.SelectedDate.length - 1]).toLocaleDateString('pt-BR')
            var csv = `Tipo,Autor,Status,Data,Hora,Informações,Ronda,Transmissão,PLC Down,PLC Upload,Altitude,Tempo de voo contínuo,Geocam,Estação Local,Windy,Tupan,Cemaden,Anemômetro,Detector de raios\n`;
            this.OperationReports.forEach(row => {
                let notes = row.operationReportObject.notes.length ? row.operationReportObject.notes : 'Sem informações';
                let ronda = row.operationReportObject.monitoringStatus == 'tour' ? 'Autom.' : 'Manual';
                let livestream = row.operationReportObject.liveStreamStatus ? 'Ao vivo' : 'Sem transmissão';
                
                let continousFlight = row.operationReportObject.aerostatInfo.flightHours ?
                this.AddZero(continousFlight = row.operationReportObject.aerostatInfo.flightHours) + ':' +
                this.AddZero(row.operationReportObject.aerostatInfo.flightMinutes) :
                continousFlight = 'N/I'
                
                let meteorology = this.GetMeteorologyData(row.operationReportObject, ' ')
                let meteorologyDataGeocam = meteorology.DataGeocam
                let meteorologyDataLocalStation = meteorology.DataLocalStation
                let meteorologyDataWindy = meteorology.DataWindy
                let meteorologyDataTupan = meteorology.DataTupan
                let meteorologyDataCemaden = meteorology.DataCemaden
                let meteorologyDataLocal = meteorology.DataLocal
                let meteorologyLightningDetector = meteorology.LightningDetector

                csv+=row.operationReportObject.type;
                csv+=','+row.operationReportObject.author;                        
                csv+=','+this.GetTranslatedStatus(row.operationReportObject.aerostatInfo.status)
                csv+=','+new Date(row.operationReportObject.timestamp).toLocaleDateString('pt-BR');
                csv+=','+new Date(row.operationReportObject.timestamp).toLocaleTimeString('pt-BR'); 
                csv+=','+notes;
                csv+=','+ronda;
                csv+=','+livestream;
                csv+=','+row.operationReportObject.plcTransmission.download;                        
                csv+=','+row.operationReportObject.plcTransmission.upload;                        
                csv+=','+row.operationReportObject.aerostatInfo.altitude;                        
                csv+=','+continousFlight;                        
                csv+=','+meteorologyDataGeocam;                        
                csv+=','+meteorologyDataLocalStation;                        
                csv+=','+meteorologyDataWindy;                        
                csv+=','+meteorologyDataTupan;                        
                csv+=','+meteorologyDataCemaden;                                            
                csv+=','+meteorologyDataLocal;                                            
                csv+=','+meteorologyLightningDetector;                                            
                csv += '\n';                     
            });      
            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = `Dados de disponibilidade ${initialDate} - ${finalDate}.csv`;
            hiddenElement.click();
        },
        GetTranslatedStatus(status) {
            let translated_status = ''

            switch (status) {
                        case 'takeoff':
                            translated_status = 'Início de voo'
                            break
                        case 'flying':
                            translated_status = 'Voando'
                            break
                        case 'flying-no-problem':
                            translated_status = 'Voando sem problemas'
                            break
                        case 'flying-with-problem':
                            translated_status = 'Voando com problemas'
                            break
                        case 'partial':
                            translated_status = 'Recolhimento Parcial'
                            break
                        case 'landing':
                            translated_status = 'Recolhimento Total'
                            break
                        default:
                            break
                    }
            return translated_status
        },
        GetMeteorologyData(data, escape) {
            let meteorology = new Object
            meteorology.DataGeocam = 'Não informado',
            meteorology.DataLocalStation = 'Não informado',
            meteorology.DataWindy = 'Não informado',
            meteorology.DataTupan = 'Não informado',
            meteorology.DataCemaden = 'Não informado',
            meteorology.DataLocal = 'Não informado',
            meteorology.LightningDetector = 'Não informado'
            if (data.weatherDataObject) {
                meteorology.DataGeocam = data.weatherDataObject.geocam ? 'Altitude:' + data.weatherDataObject.geocam.aerostatAltitude + ' Inclinação:' + data.weatherDataObject.geocam.cableInclination : 'Não informado'

                meteorology.DataLocalStation = data.weatherDataObject.localStation.atmosphericPressure ? 'Pressão:' + data.weatherDataObject.localStation.atmosphericPressure + escape + 'Ventos:' + data.weatherDataObject.localStation.windSpeed : 'Não informado'

                meteorology.DataWindy = data.weatherDataObject.windy.gustSpeed ? 'Rajadas:' + data.weatherDataObject.windy.gustSpeed + ' Ventos:' + data.weatherDataObject.windy.windSpeed : 'Não informado'

                meteorology.DataTupan = data.weatherDataObject.tupan ? data.weatherDataObject.tupan.split('-').join(' ') : 'Não informado'

                meteorology.DataCemaden = data.weatherDataObject.cemaden.clouds ? 'Nuvens: ' + data.weatherDataObject.cemaden.clouds : 'Não informado'

                meteorology.DataLocal = data.weatherDataObject.local.windSpeed ? 'Ventos:' + data.weatherDataObject.local.windSpeed : 'Não informado'

                if (data.weatherDataObject.lightningDetector) {
                    meteorology.LightningDetector = data.weatherDataObject.lightningDetector.distance ? 'Distância: ' + data.weatherDataObject.lightningDetector.distance + 'm' : 'Não informado'
                }
            }
            return meteorology
        }
    },
    created() {
        this.GetOperationReportsDatesAvailable()
    },
}
</script>