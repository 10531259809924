<template>
    <form @submit.prevent="ConfirmInsertLivestream">
        <b-field label-position="on-border">
            <template slot="label">
                <span class="has-text-primary is-family-sans-serif">Provedor da transmissão</span>
            </template>
            <div class="has-text-left pt-3">
                <b-radio v-model="NewLivestreamObject.Provider" name="kinesis" native-value="kinesis">
                    Kinesis
                </b-radio>
                <b-radio v-model="NewLivestreamObject.Provider" name="vimeo" native-value="vimeo">
                    Vimeo
                </b-radio>
                <b-radio v-model="NewLivestreamObject.Provider" name="youtube" native-value="youtube">
                    YouTube
                </b-radio>
            </div>
        </b-field>
        <b-field label-position="on-border">
            <template slot="label">
                <span class="has-text-primary is-family-sans-serif">URL/Nome da transmissão de vídeo</span>
                <b-tooltip type="is-dark" label="Suporta atualmente transmissões do Vimeo e YouTube.">
                    <b-icon size="is-small" icon="question-circle"></b-icon>
                </b-tooltip>
            </template>
            <b-input v-model="NewLivestreamObject.Url" icon="play" expanded></b-input>
        </b-field>
        <b-field label-position="on-border">
            <template slot="label">
                <span class="has-text-primary is-family-sans-serif">Descrição</span>
            </template>
            <b-input v-model="NewLivestreamObject.Description" icon="pen" expanded></b-input>
        </b-field>
        <b-field label-position="on-border">
            <template slot="label">
                <span class="has-text-primary is-family-sans-serif">Operação/Empregador</span>
            </template>
            <b-select v-model="NewLivestreamObject.Flag" expanded>
                <option v-for="flag in GetCurrentUser.flag" :key="flag.name" :value="flag.name">{{ flag.alias }}</option>
            </b-select>
        </b-field>
        <b-button native-type="submit" type="is-primary" :loading="IsConfirmButtonSpinning" expanded outlined><span class="is-family-sans-serif">Confirmar</span></b-button>
    </form>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
    name: 'LivestreamInsert',
    data() {
        return {
            NewLivestreamObject: { Provider: '', Url: '', Description: '', Flag: { name: '' }, Timestamp: '' },
            IsConfirmButtonSpinning: false
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetStopReport() {
            let stopReport = this.$store.getters["database/GetStopReport"]
            if (stopReport.timestamp) return this.$store.getters["database/GetStopReport"]
            else return null
        }
    },
    methods: {
        ConfirmInsertLivestream() {
            this.IsConfirmButtonSpinning = true
            this.NewLivestreamObject.Timestamp = new Date().toISOString()
            this.$store.dispatch('database/PushToDatabase', { path: 'streams/' + this.NewLivestreamObject.Flag.toLowerCase(), object: this.NewLivestreamObject })
                .then(() => {
                    let stopReport = this.GetStopReport
                    if (stopReport && stopReport.timestamp) this.RemoveStopReport(stopReport)
                    this.IsConfirmButtonSpinning = false
                    this.LaunchToast('Transmissão de vídeo inserida com sucesso!', 'is-success')
                })
                .catch(error => {
                    this.IsConfirmButtonSpinning = false
                    this.LaunchToast('Erro ao inserir transmissão de vídeo: ' + error, 'is-danger')
                })
        },
        RemoveStopReport(stopReport) {
            stopReport.end = Date.now()
            let modifiedDate = new Date(stopReport.timestamp).toLocaleDateString('pt-br').split('/').join('-')
            let inactiveStopReportPath = 'stop-reports/inactive/' + stopReport.flag.name.toLowerCase() + '/' + modifiedDate
            let activeStopReportPath = 'stop-reports/active/' + stopReport.flag.name.toLowerCase()

            this.$store.dispatch('database/PushToDatabase', { path: inactiveStopReportPath, object: stopReport })
                .then(() => { this.$store.dispatch('database/RemoveFromDatabase', { path: activeStopReportPath, object: stopReport }) })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: 'is-bottom',
                duration: 3000
            })
        }
    },
    created() {
        this.$store.dispatch('database/bindHirers')
    }
}
</script>