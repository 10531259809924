<template>
    <div class="columns is-multiline">
        <div class="column has-text-left">
            <b-field label-position="on-border" grouped group-multiline>
                <template slot="label">
                    <span class="has-text-primary is-family-sans-serif">Selecione a(s) data(s):</span>
                </template>
                
                <b-datepicker v-model="SelectedDate" icon="calendar-alt" trap-focus multiple>
                </b-datepicker>
                <p class="control">
                    <b-button class="button is-primary" @click="ConfirmSelectedDates" expanded outlined><span class="is-family-sans-serif">Confirmar</span></b-button>
                </p>
                <p class="control">
                    <b-button class="button is-warning" @click="GenerateCsv" v-if="showTable" icon-left="file-csv"  expanded outlined>
                        <span class="is-family-sans-serif">CSV</span>
                    </b-button>
                </p>
            </b-field>
        <div class="ml-5" v-if="showTable">
            <p class="is-size-4 has-text-weight-bold is-uppercase">Tempo de ronda ativa {{ GetCurrentCamera }}</p>  
            <p class="has-text-weight-semibold">Dias: <span class="has-text-weight-medium  ">{{days}}</span></p>
            <p class="has-text-weight-semibold">Horas: <span class="has-text-weight-medium  ">{{hours}}</span></p>
            <p class="has-text-weight-semibold">Minutos: <span class="has-text-weight-medium  ">{{minutes}}</span></p>
            <p class="has-text-weight-semibold">Segundos: <span class="has-text-weight-medium  ">{{seconds}}</span></p>
            <p class="has-text-weight-semibold">Atualizações: <span class="has-text-weight-medium  ">{{updates}}</span></p>
        </div>
        </div>
    </div>
</template>
<style lang="scss" scope>
</style>
<script>

export default {
    name: 'ReportRonda',

    data() {
        return {
            showTable: false,
            SelectedDate: [],
            rondaActiveLog: [],
            rondaActiveTime: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            updates: 0
        }
    },
    computed: {
        GetCurrentUser() {
            return this.$store.getters['user/GetCurrentUser']
        },
        GetCurrentCamera() {
            return this.$store.getters['database/GetCurrentCamera']
        }
    },
    watch: {
        GetCurrentCamera: {
            handler() {
                this.SelectedDate = []
                this.showTable = false
            }
        }
    },
    methods: {
       
        ConfirmSelectedDates() {
            this.GetRondaActiveTime()
        },
        GetRondaActiveTime() {
            this.rondaActiveLog = []
             this.SelectedDate.forEach(date => {
                date = new Date(date).toLocaleDateString('pt-BR').split('/')
                this.$store.dispatch('database/GetFromDatabase', {
                path: 'mordor-history/' + this.GetCurrentUser.activeFlag.toLowerCase() + '/ronda/'+this.GetCurrentCamera+'/' +date[0]+'-'+date[1] + '-' + date[2],
                returnSnapshotVal: false
                }).then(stream=>{
                    stream.forEach(element => {
                        this.rondaActiveLog.push(element.val())
                    });
                    this.CalculeRondaActiveTime()
                })
            })
        },
        CalculeRondaActiveTime() {
            this.rondaActiveTime=0
            this.rondaActiveLog.forEach((element, idx) => {
                if (element.status == 1) {
                    let nextDate = ''
                    let currentDate = element.date
                    try {
                        nextDate = this.rondaActiveLog[idx + 1].date
                    } catch {
                        nextDate = Date.now()
                    }
                    let timeDiff = Math.abs(nextDate - currentDate)
                    this.rondaActiveTime += timeDiff
                }
            })
            this.updates = this.rondaActiveLog.length
            this.timeDifference(this.rondaActiveTime)
        },
        timeDifference(timeDiff) {
            this.days = Math.floor(timeDiff/1000/60/60/24);
            timeDiff -= this.days*1000*60*60*24

            this.hours = Math.floor(timeDiff/1000/60/60);
            timeDiff -= this.hours*1000*60*60

            this.minutes = Math.floor(timeDiff/1000/60);
            timeDiff -= this.minutes*1000*60

            this.seconds = Math.floor(timeDiff/1000);

            this.showTable = true
        },
        GenerateCsv(){
            let initialDate = new Date(this.SelectedDate[0]).toLocaleDateString('pt-BR')
            let finalDate = new Date(this.SelectedDate[this.SelectedDate.length - 1]).toLocaleDateString('pt-BR')
            var csv = 'Usuario, Empresa, Ação, Data, Hora\n';
            this.rondaActiveLog.forEach(row => {
                let status = row.status == 0 ? 'Desativada' : 'Ativada';
                let company = row.company ? row.company : 'Não informado'
                csv+=row.login;
                csv+=','+company;                        
                csv+=','+status
                csv+=','+new Date(row.date).toLocaleDateString('pt-BR');
                csv+=','+new Date(row.date).toLocaleTimeString('pt-BR'); 
                csv += '\n';                     
            });      
            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = `Dados Ronda ${initialDate} - ${finalDate}.csv`;
            hiddenElement.click();
        },

    }
}
</script>