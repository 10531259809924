<template>
    <div>
        <span class="is-hidden">{{ UserFormerHirers }}</span><!-- Hack to render flag from props. WIP. -->
        <b-taginput v-model="SelectedHirers" :data="FilteredHirers" @typing="GetFilteredHirers" field="name" @input="hirers => $emit('input', hirers)" autocomplete :allow-new="false" :open-on-focus="true" :maxtags="20" :has-counter="false" icon="tags">
        </b-taginput>
    </div>
</template>
<script>
export default {
    name: 'HirerAutocompleteTagInput',
    props: {
        UserFormerHirers: Array
    },
    data() {
        return {
            SelectedHirers: [], //Current SelectedHirer hirer from autocomplete list.
            FilteredHirers: [],
        }
    },
    computed: {
        GetHirers() {
            return this.$store.getters['database/GetHirers']
        },
    },
    methods: {
        GetFilteredHirers(text) { //Filter user input to search on autocomplete list.
            this.FilteredHirers = this.GetHirers.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },
    },
    beforeUpdate() {
        this.SelectedHirers = this.UserFormerHirers
    },
    created() {
        this.$store.dispatch('database/bindHirers')
        this.FilteredHirers = this.$store.getters['database/GetHirers']
    }
}
</script>